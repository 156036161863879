@import "../../../../scss/variables";
.quizDetailContainer {
  width: 100%;
  .quizDetailBox {
    width: 100%;
    margin-bottom: 20px;
    .title {
      margin-bottom: 20px;
      color: $text-black-color;
      font-size: $font-size-14;
      font-weight: 700;
      font-family: $font-lato;
      text-transform: capitalize;
    }
    .subtitle {
      margin-bottom: 10px;
      color: $text-black-color;
      font-size: $font-size-20;
      font-weight: 700;
      font-family: $font-lato;
      text-transform: capitalize;
    }
    .descraption {
      color: $text-black-color;
      font-size: $font-size-14;
      font-weight: 400;
      font-family: $font-lato;
      line-height: 24px;
    }
  }

  .quizQuestionListBox {
    width: 100%;
    .quizList {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      min-height: 40px;
      border: 1px solid $gainsboro;
      border-radius: 4px;
      padding: 0px 10px;
      font-size: $font-size-14;
      color: $text-black-color;
      font-weight: 600;
      font-family: $font-lato;
      margin-bottom: 10px;
    }
    .quizWithDetailBox {
      .quizWithDetailAns {
        border: 1px solid $gainsboro;
        padding: 30px;
        border-radius: 4px;
        .quesTitle {
          margin-bottom: 20px;
          color: $text-black-color;
          font-size: $font-size-16;
          font-weight: 700;
          font-family: $font-lato;
        }
        .quesImg {
          width: 100%;
          img {
            width: 100%;
            height: auto;
            max-height: 300px;
            object-fit: cover;
          }
        }
        .answerList {
          margin-top: 15px;
          ul {
            counter-reset: listStyle;
            li {
              margin-top: 15px;
              counter-increment: listStyle;
              padding-left: 50px;
              height: 40px;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              background-color: $approx-snow;
              border: 1px solid transparent;
              font-size: $font-size-14;
              color: $text-black-color;
              font-weight: 400;
              font-family: $font-lato;
              &.selected {
                background-color: $answer-select;
              }
              &:nth-child(odd) {
                border: 1px solid $gainsboro;
              }
              &:nth-child(even) {
                border: 1px solid $gainsboro;
              }
              &::before {
                margin-right: 1em;
                content: counter(listStyle, lower-alpha);
                width: 40px;
                height: 40px;
                background-color: $light-silver;
                position: absolute;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $font-size-16;
                font-family: $font-lato;
                font-weight: 700;
                color: $text-black-color;
                text-transform: uppercase;
              }
            }
          }
          .answerError {
            font-size: $font-size-14;
            color: $red-color;
            margin-top: 10px;
            font-family: $font-lato;
          }
        }
      }
      .quizBtn {
        text-align: right;
        display: flex;
        justify-content: space-between;
        .overideBtn {
          max-width: 200px;
          margin: 30px 0 0 0;
        }
      }
    }
  }
}
