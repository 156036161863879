.addmultiuser_btn {
  display: flex;
  justify-content: flex-end;
}

.heading_style {
  color: #333333;
  font-weight: 700;
  font-size: 22px;
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.adduser_btn {
  display: flex;
  justify-content: flex-end;
}

.cancle_heading_style {
  color: #1b436e;
  font-weight: 600;
  margin-right: 50px;
  margin-top: 12px;
  cursor: pointer;
}
.errorMsg {
  color: red;
}
.imageverified {
  position: relative;
  top: 5px;
  left: 10px;
}

.serviveTypeStyle{
  margin-top: 9px;
    font-family: 500;
    font-weight: 700;
    color: #1b436e;
}
