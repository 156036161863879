
@import "../../../scss//variables";
.overRideCardbox{
  width: 100%;
  .header {
      display: flex;
      justify-content: space-between;
      align-items: space-between;
      width: 95%;
      .content {
        // display: flex;
        // flex-direction: column;
        // align-items: flex-start;
        margin-left: 18px;
        h1 {
          font-weight: 600;
          font-size: $font-size-22;
          color: $text-black-color;
        }
        p {
          font-weight: 600;
          font-size: $font-size-18;
          color: $shadow-grey;
          text-align: left;
        }
      }
    }
}

[data-theme="dark"]{
  .overRideCardbox{
    .header {
        .content {
          h1 {
            color: $white-color;
          }
          p {
            color: $smoke-color;
          }
        }
      }
  }
}