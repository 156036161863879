@import "../../scss/variables";

.verify-mobile {
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  font-family: $font-lato;
  background-color: $white-color;
  &-container {
    margin: 20px;
    margin-top: 50px;
    @media screen and (min-width: 768px) {
      display: flex;
      padding-top: 5%;
      margin-top: 0px;
      justify-content: space-between;
    }
  }

  &-left {
    @media screen and (min-width: 768px) {
      max-width: 310px;
      width: 100%;
      margin-left: 4%;
      display: flex;
      flex-direction: column;
    }

    .logo {
      width: 170px;
      max-width: 100%;
    }
    .logoDark {
      display: none;
      text-align: left;
    }
    .logoLight {
      display: block;
      text-align: left;
    }
    h1 {
      font-weight: 700;
      font-size: $font-size-30;
      line-height: 36px;
      color: $text-black-color;
      margin-top: 30px;
      @media screen and (min-width: 768px) {
        display: flex;
      }
    }
    .form-wrapper {
      .input-control {
        background-color: $white-color;
        border: 1px solid $Stroke-Fossil-Grey;
        &.border-red {
          border: 1px solid $red-color;
        }
      }
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: $font-size-16;
      line-height: 19px;
      margin-top: 10px;
      color: $shadow-grey;
      text-align: left;

      @media screen and (min-width: 768px) {
      }
    }
  }

  &-right {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 10%;
    @media screen and (max-width: 1024px) {
      display: none;
    }

    img {
      margin-top: 20px;
    }
  }

  &-button {
    margin-top: 20px;
    display: flex;
  }
}

.enter-otp-image {
  width: 500px;
}

.initial {
  input {
    padding-left: 45px !important;
  }
}

[data-theme="dark"] {
  .verify-mobile {
    background-color: $black-color;
    &-left {
      h1 {
        color: $white-color;
      }
      .logoDark {
        display: block;
      }
      .logoLight {
        display: none;
      }
      .form-wrapper {
        .input-control {
          background-color: $space-grey-color;
          color: $white-color;
          &.border-red {
            border: 1px solid $red-color;
          }
        }
      }
    }
  }
}
