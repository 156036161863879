@import "../../scss/variables";

.overideNotAc {
  .notAcceptableBox {
    border: 1px solid #dfdfdf;
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 20px;
    min-width: 720px;
    .overideFormDropBox {
      .dropbox{
        border: none;
        height: auto;
      }
    }
    .inVoledParty {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-left: 0px;
      .overideLables {
        .formLabel {
          margin-bottom: 0px;
          margin-right: 20px;
        }
      }
    }
  }
  .notAcceptableBoxcontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 326px;
    align-items: center;
    .overideLables {
      .formLabel {
        margin-bottom: 0px;
      }
    }
  }
  .wrapInline {
    flex-grow: 1;
  }

  // .gapBetween: {
  //   padding: theme.spacing(2),
  //   text-align: center,
  //   color: theme.palette.text.secondary,
  // },
}
.customMultiLable {
  position: relative;
  .dropbox {
    border: none;
    height: auto;
    justify-content: flex-start;
    width: fit-content;
    .multipleIconContainer {
      display: flex;
      .multipleIconImg {
        margin-right: 5px;
      }
    }
  }
}
.pastVioltionBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .overrideDeletableChip {
    .countChip {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .pastVioltion {
        font-size: $font-size-16;
        color: $nobg;
        font-weight: 700;
        font-family: $font-lato;
        margin-left: 10px;
      }
    }
  }
}
