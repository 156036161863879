@import "../../../../scss/variables";

.categoryTitle {
  background-color: $cerulean-color;
  padding: 10px;
  border-radius: 4px;
  color: $white-color;
  font-size: $font-size-16;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .close {
    font-size: $font-size-18;
    font-weight: 500;
    cursor: pointer;
  }
}
.subCategoryTitle {
  background-color: $white-color;
  border: 1px solid $cerulean-color;
  padding: 10px;
  border-radius: 4px;
  color: $shadow-grey;
  font-size: $font-size-16;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .close {
    font-size: $font-size-18;
    font-weight: 500;
    cursor: pointer;
  }
}

.questionBox {
  background-color: $fog-grey;
  padding: 5px 10px;
  border-radius: 4px;
  &.mainQuestion {
    background-color: $white-color;
  }
}
.questionItem {
  background-color: $white-color;
  border: 1px solid $gainsboro;
  padding: 10px 10px 5px 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  padding-left: 25px;
}
.inspectionSet {
  &.table_style_ui {
    height: 100%;
    min-height: 400px;
    .overrideDataGrid {
      min-height: 400px;
      .MuiDataGrid-footerContainer {
        display: none;
      }
    }
  }
}
