@import "../../../../scss/variables";
.otherIncOver {
  padding: 25px;
  text-align: left;
  padding-bottom: 15px;
  padding-top: 0px;
  .incetitle {
    margin-bottom: 10px;
    color: $text-black-color;
    font-size: $font-size-16;
    font-family: $font-lato;
    font-weight: 700;
  }
  .incbtnlist {
    .button {
      max-width: 200px;
      margin-right: 15px;
      max-height: 40px;
      line-height: 40px;
      margin-bottom: 10px;
    }
    .button:hover{
      background: $cerulean-color;
      cursor: default;
    }
  }
}
