@import "../../../../scss/variables";
// .tabuserlmstab {
//   margin-left: 1rem;
// }
.filter_btn {
  cursor: pointer;
}
button {
  cursor: pointer;
}

.lessonsList {
  margin-top: 50px;
  .rightFilterlms {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // justify-content: center;
    //justify-content: flex-end;
    //margin-left: 1rem;
    @media only screen and (max-width: 576px) {
      justify-content: flex-start;
    }

    .searchfilter {
      border: 1px solid $fossil-grey;
      background-color: $white-color;
      border-radius: 15px;
      max-width: 204px;
      width: 100%;
      height: 100%;
      max-height: 44px;
      label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        span {
          display: flex;
          padding-left: 10px;
        }
      }
      .searchfilterInput {
        height: 100%;
        input {
          border: none;
          height: 100%;
          width: 100%;
          border-radius: 15px;
          font-family: $font-lato;
          font-size: $font-size-16;
          color: $manatee-color;
          //font-weight: 600;
          &::placeholder {
            color: $manatee-color;
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
    .filter_btn {
      max-width: 130px;
      width: 100%;
      height: 100%;
      max-height: 44px;
      justify-content: center;
      // margin-left: 20px;
      z-index: 10;
      .btn {
        border: 1px solid #dde2e5;
        background-color: #fff;
        border-radius: 15px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $shadow-grey;
        font-size: $font-size-16;
        font-weight: 600;
        font-family: $font-lato;
        cursor: pointer;
        .filterIcons {
          color: $shadow-grey;
          margin-left: 10px;
          font-weight: 700;
          font-size: $font-size-26;
        }
      }
    }
  }
  .tabcustom {
    padding: 0px 0px 10px 0px;
    font-size: $font-size-16;
    font-family: $font-lato;
    color: $spanish-gray;
    margin-right: 37px;
    font-weight: 700;
    text-transform: capitalize;
    &.Mui-selected {
      color: $cerulean-color;
    }
    .totalV {
      background-color: $giants-orange;
      width: 22px;
      height: 22px;
      border-radius: 100%;
      color: $white-color;
      font-family: $font-lato;
      font-size: $font-size-12;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
    }
  }
  .MuiTabs-indicator {
    bottom: 24px;
    background-color: $cerulean-color;
  }
  &.noIn {
    .MuiTabs-indicator {
      bottom: 16px;
    }
  }

  .tabPanel {
    width: 100%;
    margin-top: 20px;
  }
}
