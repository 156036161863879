.filterListCheckbox{
    margin-right:10px;
   
    
}
.filterlist_of_cases{
 
    .filterLabel {
        color: #666666;
        font-size: 16px;
        font-weight: 600;
      }
}