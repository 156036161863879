.filterListCheckbox {
  margin-right: 10px;
}

.permitFilter {
  .filterlist_of_cases {
    text-transform: capitalize;
  }
  .permitFilterDate {
    display: flex;
    justify-content: space-between;
    .approvalDate {
      width: 48%;
    }
  }
}

.filterlist_of_cases{
  
    .filterLabel{
      color: #666666;
      font-size: 16px; 
      font-weight: 600;
  }
  
}