.inspectionPlatform {
  display: flex;

  .inspectionPlatformType {
    display: flex;
    width: 100%;
  }
  .blockView {
    strong {
      font-weight: 600;
    }
  }
}

.commonOveride {
  .commoninfoWrap {
    .commoninfoBox {
      .commoninfoleft {
        .listView.inspectionPlatform {
          .blockView {
            width: 25%;
            &.inspectionPlatformTypeTitle {
              width: 40%;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
