@import "../../../scss/variables";

.overideInputField {
  .icon {
    color: #999999;
  }
  .formControlRoot {
    background: $white-color;
    border: 1px solid $border-grey;
    border-radius: 18px;
    font-family: $font-lato;
    font-style: normal;
    font-weight: 400;
    font-size: $font-size-14;
    color: $manatee-color;
    margin-bottom: 0px;
  }
  .inputRoot {
    border-radius: 16px;
    margin-bottom: 0px;
    padding: 7.5px 15px;
    &.Mui-disabled {
      background-color: $fog-grey;
    }
  }
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -25px;
    left: 0;
  }
}

[data-theme="dark"] {
  color: $white-color;
  .overideInputField {
    color: $white-color;
    .formControlRoot {
      background-color: $space-grey-color;
      border: 1px solid $fossil-grey;
      color: $white-color;
      .inputRoot {
        color: $white-color;
        opacity: 1;
        &.Mui-disabled {
          background-color: $black-color;
          color: $white-color;
          .css-nz481w-MuiInputBase-input-MuiInput-input.Mui-disabled {
            -webkit-text-fill-color: $manatee-color;
            color: $manatee-color;
          }
        }
        .css-nz481w-MuiInputBase-input-MuiInput-input.Mui-disabled {
          -webkit-text-fill-color: $manatee-color;
        }
      }
    }
  }
}
