@import "../../../scss//variables";

.overrideFilterButton {
  button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: $font-lato;
    color: $shadow-grey;
    text-align: center;
    font-size: $font-size-16;
    font-weight: 600;
    width: 106px;
    height: 44px;
    background: $white-color;
    border: 1px solid $gainsboro;
    border-radius: 15px;
    #icn {
      font-size: $font-size-18;
      color: $shadow-grey;
    }
  }
  .filterbtns {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: $font-lato;
    color: $shadow-grey;
    text-align: center;
    font-size: $font-size-16;
    font-weight: 600;
    width: 100%;
    height: 44px;
    background: $white-color;
    border: 1px solid $gainsboro;
    border-radius: 15px;
    padding: 5px 20px;
    line-height: 28px;
    margin-left: 10px;
    svg#icn {
      font-size: $font-size-24;
      position: relative;
      top: 0px;
      right: 0px;
      color: $manatee-color;
    }
  }
}
