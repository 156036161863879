@import "../../../../src/scss//variables";

.overrideDataGrid {
  .MuiDataGrid-columnHeaders {
    font-family: $font-lato;
  }
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 600;
    color: $text-black-color;
  }

  // .MuiSvgIcon-root {
  //   font-size: $font-size-30;
  // }
  // Removed as we need to show the Pagination
  // @media (min-width: 600px) {
  //   .MuiDataGrid-selectedRowCount {
  //     visibility: hidden;
  //   }
  // }
}
.table_style_ui {
  .overrideDataGrid {
    font-family: $font-lato;
    .MuiDataGrid-row:hover {
      background-color: $gainsboro;
    }
  }
}
