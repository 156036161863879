@import "../../scss/variables";

.change-password {
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: $white-color;
  &-container {
    margin: 20px;
    margin-top: 50px;
    @media screen and (min-width: 768px) {
      display: flex;
      padding-top: 5%;
      margin-top: 0px;
      justify-content: space-between;
    }
  }
  &-left {
    @media screen and (min-width: 768px) {
      max-width: 50%;
      margin-left: 4%;
      display: flex;
      flex-direction: column;
    }

    .logo {
      width: 170px;
    }
    .logoDark {
      display: none;
      text-align: left;
    }
    .logoLight {
      display: block;
      text-align: left;
    }
    .reset-password-title {
      font-weight: 600;
      font-size: $font-size-30;
      line-height: 36px;
      color: $text-black-color;
      margin-top: 20px;

      @media screen and (min-width: 768px) {
        display: flex;
      }
      .form-wrapper {
        .password-container {
          h1 {
            background-color: $white-color;
          }
          p {
            margin-bottom: 10px;
          }
        }
        .input-control {
          background-color: $white-color;
          border: 1px solid $border-grey;
          &.border-red {
            border: 1px solid $red-color;
          }
        }
        .showHidePassword {
          filter: initial;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  &-right {
    display: none;
    @media screen and (min-width: 768px) {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
      margin-right: 10%;
    }
  }

  &-button {
    margin-top: 20px;
  }
}

.forgot-password {
  font-style: normal;
  font-weight: 600;
  font-size: $font-size-14;
  line-height: 20px;
  text-align: right;
  color: $cerulean-color;
  margin-top: 20px;
  cursor: pointer;
}

.valid {
  color: $ocean-green;
  padding: 7px;
  display: flex;
  align-items: center;
  text-align: left;
}
.invalid {
  color: $shadow-grey;
  padding: 7px;
  display: flex;
  align-items: center;
  text-align: left;
}
.checkbox {
  font-size: 20px;
  margin-right: 5px;
}

[data-theme="dark"] {
  .reset-password {
    background-color: $black-color;
    .reset-password-title {
      color: $white-color;
    }
    .logoDark {
      display: block;
    }
    .logoLight {
      display: none;
    }
    .form-wrapper {
      .password-container {
        h1 {
          color: $white-color;
        }
        p {
          color: $white-color;
          margin-bottom: 10px;
        }
      }
      .input-control {
        background-color: $space-grey-color;
        color: $white-color;
        &.border-red {
          border: 1px solid $red-color;
        }
      }
      .showHidePassword {
        filter: brightness(30);
        width: 20px;
        height: 20px;
      }
    }
  }
}

.form-wrapper {
  .password-container {
    p {
      margin-bottom: 10px;
    }
  }
}
.showHidePassword {
  width: 20px;
  height: 20px;
}
