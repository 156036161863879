@import "../../scss/variables";

.signup {
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: $white-color;
  text-align: left;
  &-container {
    margin: 20px;
    margin-top: 50px;
    @media screen and (min-width: 768px) {
      display: flex;
      padding-top: 5%;
      margin-top: 0px;
      justify-content: space-between;
    }
  }

  &-left {
    @media screen and (min-width: 768px) {
      max-width: 460px;
      width: 100%;
      margin-left: 4%;
      display: flex;
      flex-direction: column;
    }

    .logo {
      width: 170px;
      max-width: 100%;
    }
    .logoDark {
      display: none;
    }
    .logoLight {
      display: block;
    }
    .signup-title {
      font-style: normal;
      font-weight: 700;
      font-size: $font-size-30;
      line-height: 36px;
      color: $text-black-color;
      text-align: left;
      margin-top: 30px;
    }
    .form-wrapper {
      .input-control {
        background-color: $white-color;
        border: 1px solid $Stroke-Fossil-Grey;
        &.border-red {
          border: 1px solid $red-color;
        }
      }
      .showHidePassword {
        filter: initial;
        width: 20px;
        height: 20px;
      }
    }
  }

  &-right {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 4%;
    @media screen and (max-width: 1024px) {
      display: none;
    }

    .welcome-img {
      object-fit: contain;
    }
  }
}

.signup-form-checkboxes-wrap {
  margin-top: 30px;
  cursor: pointer;
  text-align: left;
}

.styled-checkbox {
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 9;

  & + label {
    position: relative;
    padding: 0;
    font-size: $font-size-14;
    font-weight: 400;
    font-family: $font-lato;
  }

  & + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 12px;
    height: 12px;
    border: 2px solid $cerulean-color;
    border-radius: 2px;
    margin-top: -1px;
  }

  &:checked + label:before {
    background: $cerulean-color;
  }

  &:disabled + label {
    color: $philippine-silver;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: $gainsboro;
  }

  &:checked + label:after {
    content: "";
    position: absolute;
    left: 3px;
    top: 6px;
    background: $white-color;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 $white-color, 4px 0 0 $white-color,
      4px -2px 0 $white-color, 4px -4px 0 $white-color, 4px -6px 0 $white-color,
      4px -8px 0 $white-color;
    transform: rotate(45deg);
  }
}

.terms {
  color: $cerulean-color;
  font-size: $font-size-14;
  font-weight: 700;
  margin-right: 2px;
  text-decoration: none;
  font-family: $font-lato;
}

.Mandatory-fields {
  color: $red-color;
}

p {
  font-style: normal;
  font-weight: 400;
  font-size: $font-size-14;
  line-height: 20px;
  color: $shadow-grey;
  margin-top: 10px;

  b {
    text-decoration: underline;
    color: $cerulean-color;
    cursor: pointer;
    margin-left: 3px;
  }
}

.password-container {
  position: relative;
  img {
    position: absolute;
    right: 10px;
    top: 13px;
    cursor: pointer;
    width: 20px;
  }
  .pwdbox {
    &::after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-right: 20px solid $white-color;
      border-bottom: 10px solid transparent;
      top: 85px;
      left: -12px;
    }
  }
}

.already-signup-txt {
  font-weight: 600;
  font-size: $font-size-14;
  color: $shadow-grey;
  font-family: $font-lato;
  text-align: left;
  margin-top: 20px;
}
.passStr {
  p {
    margin-bottom: 5px;
  }
}

.signup-button {
  text-align: left;
  button {
    max-width: 300px;
    width: 100%;
    height: 44px;
    line-height: 44px;
    padding: 0;
    font-weight: 500;
    margin-top: 15px;
    font-family: $font-lato;
  }
}

.valid {
  color: $valid-color;
  padding: 7px;
  display: flex;
  align-items: center;
  text-align: left;
}
.invalid {
  color: $shadow-grey;
  padding: 7px;
  display: flex;
  align-items: center;
  text-align: left;
}
.checkbox {
  font-size: $font-size-20;
  margin-right: 5px;
}

[data-theme="dark"] {
  .signup {
    background-color: $black-color;
    &-left {
      .signup-title {
        color: $white-color;
      }
      .logoDark {
        display: block;
      }
      .logoLight {
        display: none;
      }

      .form-wrapper {
        .input-control {
          background-color: $space-grey-color;
          color: $white-color;
          &.border-red {
            border: 1px solid $red-color;
          }
        }
        .showHidePassword {
          filter: brightness(30);
        }
      }
    }
  }
}
