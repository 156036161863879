@import "../../../scss/variables";

.btncaseadd {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px 10px 16px;
  gap: 8px;
  position: absolute;
  width: 193px;
  height: 44px;
  left: 1055px;
  top: 127px;
  background: $cerulean-color;
  border-radius: 100px;
  color: $white-color;
}

.viewcasetitile {
  position: absolute;
  width: 300px;
  height: 37px;
  left: 143px;
  top: 134px;
  font-family: $font-lato;
  font-style: normal;
  font-weight: 700;
  font-size: $font-size-24;
  line-height: 29px;
  color: $text-black-color;
}

// .dashboard-slider {
//   padding: 20px;
//   background: $white-color;
//   box-shadow: 0px 4px 90px rgba(0, 0, 0, 0.1);
// }

.insi-stitle {
  font-family: $font-lato;
  font-style: normal;
  font-weight: 600;
  font-size: $font-size-22;
  line-height: 26px;
  color: $text-black-color;
}

.rec {
  position: absolute;
  width: 197px;
  height: 39px;
  left: 1109px;
  top: 221px;
  background: $cerulean-color;
  border-radius: 30px;
}

.dashbord_heading {
  .insi-stitle {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-family: $font-lato;
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-22;
      line-height: 26px;
      color: $text-black-color;
    }

    .caseId {
      background: $cerulean-color;
      border-radius: 30px;
      color: $white-color;
      font-family: $font-lato;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 40px;
      text-align: center;
      width: 197px;
      height: 39px;
    }
  }
}

.container1 {
  margin-top: 10px;
  width: 1000px;
  padding-left: 40px;
}

.inputsearchcase {
  box-sizing: border-box;
  position: absolute;
  width: 388px;
  height: 44px;
  left: 290px;
  top: 195px;

  background: $white-color;
  border: 1px solid $border-grey;
  border-radius: 15px;
}

.btnsearch {
  box-sizing: border-box;
  position: absolute;
  width: 106px;
  height: 44px;
  left: 712px;
  top: 195px;
  background: $white-color;
  border: 1px solid $border-grey;
  border-radius: 15px;
}

.toggle-button {
  box-sizing: border-box;

  position: absolute;
  width: 106px;
  height: 44px;
  left: 712px;
  top: 195px;

  background: $white-color;
  border: 1px solid $border-grey;
  border-radius: 15px;
}

#icn {
  font-size: 14px;
}

.location1 {
  position: absolute;
  width: 103px;
  height: 16.52px;
  margin-top: 1px;
  font-family: $font-lato;
  font-style: normal;
  font-weight: 600;
  font-size: $font-size-14;
  line-height: 17px;
  text-transform: uppercase;
  color: $text-black-color;
}

.timeperiad {
  position: absolute;
  width: 89px;
  height: 16.52px;
  font-family: $font-lato;
  font-style: normal;
  font-weight: 600;
  font-size: $font-size-14;
  line-height: 17px;
  text-transform: uppercase;
  color: $text-black-color;
}

#btnseacrh1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  width: 72px;
  height: 25px;
  border-radius: 100px;
  margin-left: 185px;
  margin-top: 5px;
  background: $cerulean-color;
  color: $white-color;
}

#tmprd {
  padding-top: 1rem;
}

.location12 {
  padding-bottom: 12px;
}

#frm {
  margin-top: -17px;
}

.caseviewpopconner {
  margin-top: -16px;
}

.Fromdate {
  margin-top: 8px;
}

.Todate {
  margin-top: 8px;
}

#hselect input[type="text"] {
  width: 200px;
  height: 35px;
  border-radius: 10px;
  outline: none !important;
}

.inputsearchcase {
  padding-left: 3rem;
}

.searchicon {
  position: absolute;
  left: 24.3%;
  right: 75.38%;
  top: 31.08%;
  bottom: 70.57%;
  background: #999999;
}

/* .MuiDataGrid-footerContainer.css-17jjc08-MuiDataGrid-footerContainer{
  border:none;
} */

.MuiDataGrid-colCellTitle {
  display: block;
  text-align: center;
  width: 100%;
}

.MuiDataGrid-columnHeaderTitle {
  display: block;
  text-align: center;
  width: 100%;
}

.MuiDataGrid-cell {
  display: block;
  position: relative;
  text-align: center;
}

.filter_of_caselist {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 700px;
  margin-bottom: 20px;

  .filterLableText {
    width: 100%;
  }

  .searchcaseid {
    position: relative;
    max-width: 50%;
    flex: 50%;

    label {
      span {
        position: absolute;
        top: 10px;
        left: 15px;

        svg {
          font-size: 30px;
        }
      }

      div {
        input {
          width: 100%;
          min-height: 45px;
          padding: 15px 15px 15px 50px;
          font-size: $font-size-16;
          border: 1px solid $Stroke-Fossil-Grey;
          border-radius: 15px;
          outline: none;
        }

        input::placeholder {
          color: #9a9fa8;
        }

        input:active,
        input:focus {
          color: #828996;
        }
      }
    }
  }

  .filter_btn {
    button {
      width: 100%;
      padding: 5px 20px;
      line-height: 28px;
      font-size: $font-size-16;
      font-family: $font-lato;
      border: 1px solid $Stroke-Fossil-Grey;
      border-radius: 15px;
      outline: none;
      color: $shadow-grey;
      background-color: transparent;
      margin-left: 10px;
      font-weight: 600;
      cursor: pointer;

      svg#icn {
        font-size: $font-size-24;
        position: relative;
        top: 8px;
        right: 5px;
        color: $manatee-color;
      }
    }
  }
}

// filter of case
.filterlist_of_cases {
  color: $text-black-color;
  padding: 5px 15px 0px 15px;

  .MuiCardContent-root {
    padding: 15px 5px;
  }

  .MuiTypography-h5 {
    text-transform: uppercase;
    font-size: $font-size-16;
    color: $text-black-color;
    font-weight: 600;
  }

  .datePicker_row {
    margin-top: 10px;

    > div {
      margin-top: 3px;

      .MuiTypography-subtitle1 {
        font-size: $font-size-14;
        font-family: $font-lato;
      }
    }

    .overideDatePicker {
      .MuiFormControl-root {
        .MuiButtonBase-root {
          svg {
            color: $cerulean-color;
          }
        }

        .MuiOutlinedInput-notchedOutline {
          border: 1px solid $fossil-grey;
          border-radius: 30px;
        }
      }
    }
  }

  .custom_filter_box {
    .MuiTypography-subtitle1 {
      font-size: $font-size-16;
      font-family: $font-lato;
      text-transform: uppercase;
      color: $text-black-color;
      font-weight: 600;
    }

    .custom_control_filter {
      width: 100%;

      .MuiInputBase-input {
        padding: 8.5px 14px;
      }

      .MuiOutlinedInput-notchedOutline {
        border: 1px solid $border-grey;
        border-radius: 30px;
      }
    }
  }

  .MuiCardActions-root {
    border-top: 0.5px solid $border-grey;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    padding-left: 0px;
    padding-right: 0px;

    .overideBtn {
      button {
        padding: 2px 30px;
      }
    }
  }
}

.addCaseListBtn {
  display: flex;
}

[data-theme="dark"] {
  .dashbord_heading {
    .insi-stitle {
      p {
        color: $white-color;
      }

      .caseId {
        background-color: $royal-blue-color;
        color: $white-color;
      }
    }
  }

  .MuiDataGrid-columnHeaders.css-f3jnds-MuiDataGrid-columnHeaders {
    background-color: $black-color;
    color: $white-color;
  }

  .table_style_ui {
    .css-1kwdphh-MuiDataGrid-virtualScrollerContent {
      .css-1m9pwf3 {
        svg {
          color: $white-color;
        }
      }
    }
  }

  .filter_of_caselist {
    .filter_btn {
      button {
        color: $white-color;

        svg#icn {
          color: $white-color;
        }
      }
    }

    .searchcaseid {
      label {
        div {
          input {
            background-color: $space-grey-color;
          }
        }
      }
    }
  }
}
.modalUsrContainer {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // .modalCustomUserBoxFirst {
  //   background-color: $white-color;
  //   padding: 35px;
  //   border-radius: 8px;
  //   // max-width: 600px;
  //   width: 100%;
  //   .ClosegrandAccesstyle {
  //     cursor: pointer;
  //     font-size: 20px;
  //     color: #333;
  //     text-align: end;
  //   }

  //   .modalTitle {
  //     text-align: center;
  //     font-size: $font-size-22;
  //     font-weight: 600;
  //     font-family: $font-lato;
  //   }
  //   .modeldesc {
  //     text-align: center;
  //     font-size: 15px;
  //     font-weight: bold;
  //     font-family: "Lato";
  //     margin-top: 2rem;
  //   }
  //   .modeldescreset {
  //     text-align: center;
  //     font-size: 16px;
  //     font-weight: bold;
  //     font-family: "Lato";
  //     margin-top: 2rem;
  //   }

  //   .modalFooterBox {
  //     justify-content: flex-end;
  //   }

  //   .grandaccess {
  //     display: flex;
  //     justify-content: center;
  //     gap: 1rem;
  //     margin-top: 2rem;
  //     margin-bottom: 1rem;
  //   }
  // }
  .modalCustomUserBox {
    background-color: $white-color;
    padding: 35px;
    border-radius: 8px;
    max-width: 800px;
    width: 100%;
    .ClosegrandAccesstyle {
      cursor: pointer;
      font-size: 20px;
      color: #333;
      text-align: end;
    }

    .modalTitle {
      text-align: center;
      font-size: $font-size-22;
      font-weight: 600;
      font-family: $font-lato;
    }
    .modeldesc {
      text-align: center;
      font-size: 15px;
      font-weight: bold;
      font-family: "Lato";
      margin-top: 2rem;
    }
    .modeldescreset {
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      font-family: "Lato";
      margin-top: 2rem;
    }

    .modalFooterBox {
      justify-content: flex-end;
    }

    .grandaccess {
      display: flex;
      justify-content: center;
      gap: 1rem;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  }
}

.resetpwd {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.restActions {
  display: flex;
  justify-content: center;
  gap: 2px;
}
