@import "../../../scss/variables";

.overideTimePicker {
  input[type="time"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    font-size: 21px;
    background-image: url(../../../assets/timepicker.svg);
    
    
}
  .formControlRoot {
    background-color: $white-color;
    border-radius: 15px;
    font-family: $font-lato;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: $manatee-color;
    margin-bottom: 0px;
   
    &.Mui-disabled {
      background-color: $fog-grey;
      border-radius: 15px;
    }
    &.MuiOutlinedInput-notchedOutline {
      border-radius: 15px;
      padding: 3px 15px;
    }
    .inputRoot {
      border-radius: 16px;
      padding: 2px 0px;
    }
  }
}

[data-theme="dark"] {
  .overideTimePicker {
    .formControlRoot {
      background-color: $space-grey-color;
      border: 1px solid $fossil-grey;
      color: $white-color;
      &.Mui-disabled {
        background-color: $black-color;
        color: $white-color;
      }
      .inputRoot {
        color: $white-color;
      }
    }
  }
}
