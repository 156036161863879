@import "../../../scss/variables";

.tableData {
  height: 500px;
  width: 100%;
  .data-colindex {
    cursor: pointer;
  }
  .MuiDataGrid-root {
    border-radius: 0px;
    border: 1px solid $gainsboro;
    .MuiDataGrid-cell {
      button {
        position: absolute;
        right: 10%;
      }
    }
    .MuiDataGrid-columnHeaders {
      color: $shadow-grey;
      font-size: $font-size-14;
      font-family: $font-lato;
      font-weight: 700;
    }
    .MuiDataGrid-footerContainer {
      border-top: 0px;
    }
  }
  .moreAction {
    .moreIcon {
      color: $shadow-grey;
    }
  }
  .assigned-to--cell {
    cursor: pointer;
  }
  .completed-byuser--cell {
    cursor: pointer;
  }
}
.openOption {
  .MuiPaper-root {
    width: 175px;
    background: $white-color;
    border: 0.5px solid $spanish-gray;
    box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    .setting_button_ui {
      width: auto;
      padding: 10px 20px;
      .dropdownList_ui {
        ul {
          li {
            color: $text-black-color;
            font-size: $font-size-14;
            font-weight: 400;
            font-family: $font-lato;
          }
        }
      }
    }
  }
}

.tableData {
  .MuiDataGrid-columnSeparator {
    .MuiSvgIcon-root {
      // visibility: hidden;
    }
  }
  .MuiDataGrid-columnHeaders {
    font-family: $font-lato;
  }
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 600;
    color: $text-black-color;
  }
  .MuiDataGrid-columnHeaderTitleContainerContent:nth-last-child(1) {
    position: absolute;
    right: 10%;
  }
  .customrowTable {
    &.MuiDataGrid-row {
      max-height: fit-content;
    }
    &.MuiDataGrid-row:hover {
      background-color: $gainsboro;
    }
    .customCellTable {
      color: $shadow-grey;
      font-size: $font-size-14;
      font-family: $font-lato;
      font-weight: 600;
      &:focus {
        border: none;
        box-shadow: none;
        outline: none;
      }
      &.MuiDataGrid-cell {
        overflow: visible;
        white-space: break-spaces;
      }
      .nameBox {
        display: flex;
        padding: 10px 0px;
        text-align: left;
        .nameImg {
          img {
            border-radius: 10px;
            width: 101px;
            height: 68px;
            object-fit: cover;
          }
        }
        .nameBoxpara {
          color: $text-black-color;
          font-size: $font-size-14;
          font-family: $font-lato;
          font-weight: 600;
          margin-left: 12px;
          white-space: break-spaces;
        }
      }
    }
  }
}
