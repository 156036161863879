@import "../../../../scss/variables";

.UserChiplistBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  .UserChiplistItem {
    padding: 10px 20px;
    border: 1px solid $cerulean-color;
    margin-right: 10px;
    border-radius: 20px;
    margin-top: 5px;
    font-size: $font-size-14;
    font-weight: 600;
    font-family: $font-lato;
  }
}
