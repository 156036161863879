@import "../../scss/variables";

.topSection {
  padding: 15px;
  border-radius: 15px;
  background-color: $light-primary-background-color;
  box-shadow: 0px 4px 100px 0px rgba(0, 0, 0, 0.07);
  min-height: 230px;
  
  .title {
    font-size: $font-size-16;
    font-weight: 600;
    font-family: $font-lato;
    color: $dark-secondary-background-color;
  }
  .topColumn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .heading {
      font-size: $font-size-14;
      font-weight: 700;
      font-family: $font-lato;
      color: $dark-secondary-background-color;
      line-height: auto;
      margin-bottom: 0px;
    }
  }
  .topRow {
    .topRowList {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $shadow-grey;
      .heading {
        font-size: $font-size-14;
        font-weight: 700;
        font-family: $font-lato;
        color: $shadow-grey;
        line-height: auto;
        margin-bottom: 0px;
      }
    }
  }
}
.cursor{
  cursor: pointer;
}