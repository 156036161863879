@import "../../../scss/variables";

.overideFormDropBox {
  .loaderDropBox {
    span {
      color: $cerulean-color;
    }
  }
  .dropBoxTitle {
    color: $shadow-grey;
    font-size: $font-size-16;
    font-weight: 600;
    text-align: left;
    margin-bottom: 12px;
  }
  .dropBoxError {
    color: red;
    font-size: $font-size-16;
    font-weight: 600;
    text-align: left;
  }
  .dropbox {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font-lato;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: $font-size-16;
    color: $shadow-grey;
    border: 1.5px dashed $gainsboro;
    border-radius: 15px;
    height: 100px;
    text-align: center;
    cursor: pointer;
  }
  .dropText {
    color: $text-black-color;
    font-size: $font-size-16;
  }
  .browseText {
    color: $text-black-color;
    font-size: $font-size-16;
    span {
      color: $cerulean-color;
      text-decoration: none;
      font-size: $font-size-16;
      font-weight: 700;
    }
  }
  .clousIcon {
    color: #999999;
    width: 50px;
    height: 34px;
  }
  .imageBody {
    display: flex;
    flex-wrap: wrap;
  }
}

[data-theme="dark"] {
  .overideFormDropBox {
    .dropBoxTitle {
      color: $white-color;
    }
    .dropbox {
      background-color: $space-grey-color;
      border: 1.5px dotted $fossil-grey;
      color: $white-color;
    }
    .browseText {
      color: $white-color;
      font-size: $font-size-16;
      span {
        color: $saphire-blue-color;
        text-decoration: none;
      }
    }
  }
}
