@import "../../scss/variables";
.overideNotAc {
  .notAcceptableBox {
    border: 1px solid #dfdfdf;
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 20px;
    min-width: 720px;
    position: relative;
    @media (max-width: 1100px) {
      min-width: 100%;
    }
    @media (max-width: 1024px) {
      min-width: 100%;
    }
    .inVoledParty {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding-left: 0px;
      .overideLables {
        .formLabel {
          margin-bottom: 0px;
          margin-right: 20px;
        }
      }
    }
  }
  .notAcceptableBoxcontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 326px;
    align-items: center;
    .overideLables {
      .formLabel {
        margin-bottom: 0px;
      }
    }
  }
  .wrapInline {
    flex-grow: 1;
  }

  // .gapBetween: {
  //   padding: theme.spacing(2),
  //   text-align: center,
  //   color: theme.palette.text.secondary,
  // },
}
.customMultiLable {
  position: relative;
  .dropbox {
    border: none;
    height: auto;
    justify-content: flex-start;
    width: fit-content;
    .multipleIconContainer {
      display: flex;
      .multipleIconImg {
        margin-right: 5px;
      }
    }
  }
}
.removeRepetBox {
  font-size: $font-size-14;
  font-weight: 500;
  font-family: $font-lato;
  color: $shadow-grey;
  position: absolute;
  right: 10px;
  top: 20px;
  cursor: pointer;
}
