@import "../../scss/variables";

.body {
  margin: 0;
  padding: 0;
}
.Sidebar {
  width: 320px;
  left: 0px;
  top: 0px;
  border-radius: 0px;
  position: fixed;
  background-color: $fog-grey;
  box-shadow: 1px 0px 0px $fog-grey;
  height: 100vh;
  .logo_sidebaar {
    display: inline-block;
    padding: 30px 15px;
    img {
      max-width: 130px;
    }
  }
  .logo {
    margin-top: 30px;
    margin-bottom: 40px;
    img {
      margin-left: 25px;
    }
  }
  ul {
    padding: 0px;
    margin: 0px;
    li {
      padding: 7px 50px;
      margin: 15px 0px;
      display: flex;
      line-height: 30px;
      div#icon {
        width: 30px;
        height: 30px;
        line-height: 40px;
      }
      div#title {
        padding: 0px 15px;
        font-weight: 600;
        color: $shadow-grey;
        font-family: $font-lato;
      }
    }
  }
}

.sidebarlist {
  height: auto;
  width: 100%;
}

.sidebarlist .rowli:hover {
  cursor: pointer;
  color: $text-black-color;
}
.Sidebar.sitelogo {
  position: absolute;
  width: 234px;
  height: 93px;
  left: 17px;
  top: 1rem;
}

.dashboard-title {
  position: absolute;
  width: 237px;
  height: 24px;
  left: 318px;
  top: 45px;
  color: $text-black-color;
}

.appMenu {
  .menuItem {
    padding-left: 35px;
    justify-content: space-between;
    .menuSubText {
      span {
        color: $shadow-grey;
        text-decoration: none;
        font-weight: 600;
        font-family: $font-lato;
        font-size: $font-size-14;
      }
    }
    .menuText {
      flex: auto;
      padding-right: 5px;
      span {
        color: $shadow-grey;
        text-decoration: none;
        font-weight: 600;
        font-size: $font-size-18;
        font-family: $font-lato;
      }
    }
    .menuIcon {
      color: $cerulean-color;
    }
  }
}
.appMenu {
  .menuItem {
    &.activeClass {
      .menuText {
        span {
          color: $cerulean-color;
        }
      }
    }
  }
}
.appMenu {
  .menuItem {
    &.activeClass {
      .menuSubText {
        span {
          color: $cerulean-color;
        }
      }
    }
  }
}
.Sidebar {
  text-align: center;
  .logo {
    .light-logo {
      display: block;
    }
    .dark-logo {
      display: none;
    }
  }
}

.sidesss {
  root {
    display: "flex";
  }
  .drawerPaper {
    width: 300px;
    background-color: transparent;
    border: none;
    z-index: 9;
    &::-webkit-scrollbar {
      width: 0px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: none;
      outline: 1px solid transparent;
    }
  }
  .content {
    flex-grow: 1;
    height: "100vh";
    overflow: "auto";
  }
  .container {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

[data-theme="dark"] {
  .Sidebar {
    background-color: $black-color;
    height: 100%;
    div#title {
      color: $white-color;
    }
  }
  .Sidebar {
    text-align: center;
    .logo {
      .light-logo {
        display: none;
      }
      .dark-logo {
        display: block;
      }
    }
  }
  .appMenu {
    .menuItem {
      .menuItemIcon {
        img {
          color: $white-color;
        }
      }
      .menuText {
        span {
          color: $white-color;
        }
      }
      .menuSubText {
        span {
          color: $white-color;
        }
      }
      .menuIcon {
        color: $white-color;
      }
    }
  }
}
