@import "../../../../scss/variables";

.inspectionOverRide {
  &.inspectionAcknowledgement {
    .inspectionAcknowledgementHeading {
      font-size: $font-size-24;
      font-weight: 700;
      font-family: $font-lato;
      color: $text-black-color;
      margin-bottom: 50px;
    }
    .contactswitch {
      .MuiFormLabel-colorPrimary {
        color: $shadow-grey;
        font-size: $font-size-16;
        font-weight: 600;
        font-family: $font-lato;
        margin-bottom: 10px;
      }
    }
    .MuiFormControlLabel-labelPlacementEnd {
      .MuiSwitch-sizeMedium {
        margin-left: 7px;
      }
    }
    .insBackBtn {
      .backBtn {
        margin-top: 10px;
      }
    }
  }
}
