@import "../../../scss/variables";

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $font-size-24;
  font-weight: 600;
  color: $light-primary-color;
  height: 100%;
}
