@import "../../../../scss/variables";
.overrideMultiSelectBox {
  width: 100%;
  .boxContainer {
    border: 1px solid $chinese-white;
    border-top: 0;
    .boxHeading {
      height: 40px;
      background-color: $cerulean-color;
      border-radius: 5px;
      .label {
        margin: 0;
        font-family: $font-lato;
        font-size: $font-size-18;
        font-weight: 700;
        color: $white-color;
      }
      .btnDiv {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        background-color: $chinese-white;
        color: $text-black-color;
        font-size: $font-size-14;
        font-weight: 600;
        font-family: $font-lato;
        border: 1px solid #79747e;
        border-radius: 5px;
        margin: 0;
        margin-top: -7px;
        cursor: pointer;
      }
      .close {
        color: $white-color;
        cursor: pointer;
        font-family: $font-lato;
      }
    }
    .actBoxHeading {
      height: 40px;
      .label {
        margin: 0;
        font-family: $font-lato;
        font-size: $font-size-18;
        font-weight: 700;
        color: $text-black-color;
      }
      .btnDiv {
        display: flex;
        align-items: center;
        padding: 0 10px;
        background-color: $chinese-white;
        color: $text-black-color;
        font-size: $font-size-14;
        font-weight: 600;
        font-family: $font-lato;
        border: 1px solid #79747e;
        border-radius: 5px;
        margin: 0;
        margin-top: -5px;
        cursor: pointer;
      }
      .close {
        color: $white-color;
        cursor: pointer;
        font-family: $font-lato;
      }
    }

    // .jhAccordianParent {
    //   border-radius: 4px;
    //   border: 1px solid #1b436e;
    //   background: #fff;
    //   margin-bottom: 10px;
    .jhAccordian {
      background-color: #1b436e;
    }
    .expandIconjha {
      color: #fff;
      font-size: 30px;
    }
    .cateTitlejha {
      .accoTitle {
        color: #fff;
        font-family: "Lato";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
      }
    }
    .jhAccordianChild {
      border-radius: 5px;
      border: 1px solid #dfdfdf;
      background: #fff;
      margin-bottom: 10px;
    }
    .jhsubCatButton {
      margin-bottom: 25px;
    }
    .accordinDetails {
      border-radius: 5px;
      background: #eee;
      .hazardContainer {
        border-radius: 5px;
        border: 1px solid #dfdfdf;
        background: #fff;
        padding: 20px;
        margin-top: 15px;
        .hazardMainContainer {
          display: flex;
        }
        .hazardNameValue {
          color: #666;
          font-family: "Lato";
          font-size: 16px;
          font-weight: 500;
          padding-top: 3px;
          padding-bottom: 12px;
          margin-left: 5px;
        }
        .hazardName {
          color: #666;
          font-family: "Lato";
          font-size: 16px;
          font-weight: 600;
          padding-top: 3px;
          padding-bottom: 12px;
          font-weight: bold;
        }
      }
    }
    // }

    .controleName {
      display: flex;
      .controleMainDiv {
        font-weight: 500;
        margin-left: 5px;
        margin-top: -5px;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          width: 0em;
        }

        &::-webkit-scrollbar-track {
          box-shadow: none;
        }

        &::-webkit-scrollbar-thumb {
          background: none;
        }
        ul li strong em h1 h2 h3 h4 h5 blockquote pre {
          // word-wrap: break-word;
          white-space: balance;
          // text-overflow: ellipsis;
        }
        p {
          white-space: balance;
          margin: 0;
        }
        ul {
          text-align: left;
          li {
            margin-bottom: 25px;
            font-size: $font-size-18;
            color: $text-black-color;
            font-weight: 500;
            padding-left: 20px;
            position: relative;
            white-space: balance;

            &::after {
              position: absolute;
              content: "";
              top: 12px;
              left: 0;
              background-color: $text-black-color;
              width: 5px;
              height: 5px;
              border-radius: 10px;
              white-space: balance;
            }
          }
        }
        color: $text-black-color;
        font-weight: 400;
        font-family: $font-lato;
        text-align: left;
        margin-bottom: 15px;
        line-height: 30px;
        white-space: balance;

        strong {
          font-weight: bold;
          color: #333333;
          white-space: balance;
        }
        em {
          font-style: italic;
          white-space: balance;
        }
        ul {
          li {
            margin-bottom: 1px;
            white-space: balance;

            // list-style: inside;
          }
        }
        h1 {
          font-size: 2em;
          font-weight: bold;
          white-space: balance;
        }
        h2 {
          display: block;
          font-size: 1.5em;
          margin-block-start: 0.83em;
          margin-block-end: 0.83em;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          font-weight: bold;
          white-space: balance;
        }
        h3 {
          display: block;
          font-size: 1.17em;
          margin-block-start: 1em;
          margin-block-end: 1em;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          font-weight: bold;
          white-space: balance;
        }
        h4 {
          display: block;
          margin-block-start: 1.33em;
          margin-block-end: 1.33em;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          font-weight: bold;
          white-space: balance;
        }
        blockquote {
          display: block;
          margin-block-start: 1em;
          margin-block-end: 1em;
          margin-inline-start: 40px;
          margin-inline-end: 40px;
          white-space: balance;
        }
        ol {
          display: block;
          list-style-type: decimal;
          margin-block-start: 1em;
          margin-block-end: 1em;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          padding-inline-start: 40px;
          white-space: balance;
        }
        pre {
          display: block;
          font-family: monospace;
          white-space: pre;
          margin: 1em 0px;
          white-space: balance;
        }
      }
      .controleStyle {
        font-weight: bold;
        min-width: max-content;
      }
      color: #666;
      font-family: "Lato";
      font-size: 16px;
      font-weight: 500;
      padding-top: 3px;
      padding-bottom: 7px;
    }
    .questionItem {
      border: none;
    }
  }
}
.mutclass {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
