@import "../../../scss/variables";

.cardBoxOver {
  width: 100%;

  @media only screen and (max-width: 576px) {
    display: flex;
    justify-content: center;
  }


  .cardBox {
    // max-width: 100%;
    max-width: 285px;


    box-shadow: 0px 4px 90px rgb(0 0 0 / 10%);
    border-radius: 10px;
    &:hover {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }
    .cardImgBox {
      position: relative;
      // cursor: pointer;
      .cardImg {
        max-height: 222px;
        min-height: 222px;
        min-width: 100%;
      }
      .cardOverlap {
        position: absolute;
        bottom: 20px;
        right: 20px;
        border-radius: 7px;
        background-color: #fff;
        padding: 5px 10px;
        font-size: $font-size-14;
        font-family: $font-lato;
        color: $shadow-grey;
        font-weight: 700;
        display: flex;
        align-items: center;
        span {
          margin-right: 5px;
        }
      }
    }
    .cardContentBox {
      padding: 10px;
      padding-bottom: 0px;
      .cardTitle {
        font-family: $font-lato;
        font-size: $font-size-18;
        color: $text-black-color;
        font-weight: 700;
        text-align: left;
        margin-bottom: 3px;
        min-height: 54px;
      }
      .centerContent {
        display: flex;
        justify-content: space-between;
        height: 30px;
        align-items: center;
        .centerContentDetail {
          font-family: $font-lato;
          font-size: $font-size-14;
          color: $spanish-gray;
          font-weight: 600;
          // width: 60%;
          text-align: left;
          &:last-child {
            text-align: right;
          }
          span {
            margin-right: 5px;
          }
        }
      }
      .bottomContent {
        display: flex;
        justify-content: space-between;
        margin: 10px 0px;
        .bottomContentDetail {
          font-family: $font-lato;
          font-size: $font-size-14;
          color: $text-black-color;
          font-weight: 600;
          display: flex;
          align-items: center;
          min-height: 21px;
          span {
            line-height: 1;
            margin-right: 5px;
          }
        }
      }
    }
    .cardBtn {
      padding: 0px;
      .cardButton {
        background-color: $green-color;
        width: 100%;
        border-radius: 0px;
        font-size: $font-size-20;
        color: $white-color;
        font-weight: 700;
        text-transform: capitalize;
        font-family: $font-lato;
        padding: 10px 0px;
        &.inprogress {
          background-color: $inprogress;
        }
        &.enroll {
          background-color: $cerulean-color;
        }
        &.start {
          background-color: $steel-blue-color;
        }
        &.unenroll {
          background-color: #D30D25;
        }
      }
    }
  }
}
