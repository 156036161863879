@import "../../../../scss/variables";

.categoriesView {
  .categoriesTitle {
    background-color: $text-black-color;
    color: $white-color;
    font-size: $font-size-18;
    padding: 10px 30px;
    border-radius: 4px;
    text-align: left;
    margin-top: 10px;
    @media print {
      margin-top: 5px;
    }
  }
  .categoriesPosTitle {
    background-color: $green-color;
    color: $white-color;
    font-size: $font-size-18;
    padding: 10px 30px;
    border-radius: 4px;
    text-align: left;
  }
  .categoriesCorrTitle {
    background-color: $nobg;
    color: $white-color;
    font-size: $font-size-18;
    padding: 10px 30px;
    border-radius: 4px;
    text-align: left;
  }
  .subCategoriesTitle {
    border: 1px solid $cerulean-color;
    background-color: $cerulean-color;
    color: $white-color;
    font-size: $font-size-18;
    padding: 10px 30px;
    border-radius: 4px;
    text-align: left;
    margin-top: 15px;
  }

  .commonInsOveride {
    .commoninfoWrap {
      margin-top: 40px;
      &:first-child {
        margin-top: 0px;
      }
      .listViewBox {
        .listDetailBox {
          width: 100%;
          div[style] {
            width: auto !important;
            position: relative !important;
          }
          @media (max-width: 767px) {
            width: 100%;
          }
          .listView {
            border-bottom: 1px solid $chinese-white;
            padding: 10px 0px;
            display: flex;
            width: 100%;
            .dFlex {
              display: flex;
            }
            .questions {
              width: 75%;
              @media (max-width: 767px) {
                width: 100%;
              }
            }
            .questionitem {
              width: 25%;
              @media (max-width: 767px) {
                width: 100%;
              }
            }
            .privies {
              align-self: center;
            }
            .overRideTextEditor {
              line-height: 1.2;
            }
            span {
              font-size: $font-size-16;
              color: $text-black-color;
              font-weight: 700;
              font-family: $font-lato;
              margin-right: 5px;
              display: block;
              min-height: 44px;
              &.minH {
                min-height: auto;
              }
            }
            .openItemCount {
              margin-top: 10px;
            }

            .notAcceptableBtn {
              background-color: $nobg;
            }
            .acceptableBtn {
              background-color: $green-color;
            }
            .notApplicableBtn {
              background-color: $notApplicable;
              &.gray {
                background-color: $gray;
              }
            }
            .yesBtn {
              background-color: $green-color;
            }
            .noBtn {
              background-color: $nobg;
            }
            .notInspectedBtn {
              background-color: $shadow-grey;
            }
            .zeroBtn {
              background-color: $green-color;
            }
            .oneBtn {
              background-color: $giants-orange;
            }
            .twoBtn {
              background-color: $nobg;
            }
            .commonZBtn {
              max-width: 60px;
              width: 60px;
              height: 42px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 0 0 auto;
              color: $white-color;
              font-size: $font-size-16;
              font-weight: 700;
            }
            .commonAcBtn {
              max-width: 120px;
              width: 120px;
              height: 42px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 0 0 auto;
              color: $white-color;
              font-size: $font-size-16;
              font-weight: 700;
            }
            .commonBtn {
              max-width: 250px;
              height: 44px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: $font-size-16;
              color: $white-color;
              font-weight: 700;
            }
            &.listNone {
              border: none;
            }
          }
        }
      }
    }
  }
}
.alignCCenter {
  align-items: center;
  justify-content: center;
  .correTitle {
    text-align: center;
    font-size: $font-size-16;
    color: $text-black-color;
    font-weight: 700;
  }
  .correShow {
    background-color: $giants-orange;
    color: $white-color;
    font-size: $font-size-32 * 2;
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 10px;
  }
}
.additionalListBox {
  .additionalList {
    margin-top: 40px;
    &:first-child {
      margin-top: 0px;
    }
  }
}

.pdfView {
  .downloadpdf {
    background-color: $green-color;
    text-align: left;
    border-radius: 20px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    color: $white-color;
    font-size: $font-size-14;
    font-weight: 400;
    margin-top: 60px;
    margin-bottom: 30px;
  }
  .downloadIcon {
    color: $white-color;
  }
}
.subCateViewBox {
  width: 100%;
  .subCateView {
    margin-top: 15px;
    &:first-child {
      margin-top: 0px;
    }
  }
}

.overSkipView {
  width: 100%;
  .skipView {
    // background-color: $shadow-grey;
    &.categorySkip {
      background-color: $text-black-color;
      margin: 20px 0px;
      display: flex;
    }
    &.subCategorySkip {
      margin: 20px 0px;
      background-color: $cerulean-color;
      display: flex;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    text-align: left;
    padding: 10px 30px;
    align-items: center;
    justify-content: space-between;
    // margin: 20px 0px;
    border-radius: 4px;
    .skipViewLeft {
      .skipViewTitle {
        font-size: $font-size-16;
        color: $white-color;
        font-weight: 700;
        font-family: $font-lato;
      }
    }
    .skipViewRight {
      .skipViewDesc {
        text-align: right;
        font-size: $font-size-16;
        color: $white-color;
        font-weight: 400;
        font-family: $font-lato;
      }
    }
  }
}

.listViewBox .seprateSection .borderSeprateSection {
  border-bottom: 2px solid $text-black-color !important;
}
.listViewBox .seprateSection .borderSeprateSection:last-child {
  border-bottom: none !important;
}
.itemFoundReport {
  border-bottom: 1px solid $chinese-white;
  padding: 10px 0px;
  align-items: center;
  span {
    font-size: $font-size-16;
    color: $text-black-color;
    font-weight: 700;
    font-family: $font-lato;
    margin-right: 5px;
  }
  .itemFoundLocation {
    .locationTextBox {
      margin-bottom: 10px;
      .locationText {
        font-size: $font-size-16;
        font-weight: 400;
        font-family: $font-lato;
        color: $text-black-color;
        margin-bottom: 5px;
      }
    }
    .locationImg {
      img {
        max-width: 80px;
        max-height: 70px;
      }
    }
    .title {
      font-size: $font-size-16;
      color: $text-black-color;
      font-weight: 700;
      font-family: $font-lato;
      margin-right: 5px;
      margin-bottom: 5px;
    }
    .statuss {
      font-size: $font-size-16;
      color: $nobg;
      font-weight: 700;
      font-family: $font-lato;
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
}
.countBtnSw {
  margin: 0 0 0 auto;
  background-color: $nobg;
  max-width: 100px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-16;
  color: $white-color;
  font-weight: 700;
  margin-top: 10px;
}
.mendetryText {
  font-size: $font-size-14;
  color: $text-black-color;
  font-weight: 500;
  font-family: $font-lato;
  margin-top: 20px;
}
.reportSignatureImg {
  margin-top: 20px;
  img {
    max-width: 200px;
    max-height: 200px;
  }
}
.downloadpdf {
  &.sw {
    background-color: $green-color;
    text-align: left;
    border-radius: 20px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    color: $white-color;
    font-size: $font-size-14;
    font-weight: 400;
    margin-top: 60px;
    margin-bottom: 30px;
    &.marginRemove {
      margin-top: 10px;
      margin-bottom: 0px;
    }
    &.float_right{
      float: right;
    }
  }
  .downloadIcon {
    color: $white-color;
  }
}

.hbLogo {
  text-align: right;
  img {
    width: 100px;
    @media print {
      width: 50px;
    }
  }
}