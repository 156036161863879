@import "../../../../scss/variables";
.overrideadmincategory {
    .createFaqh1 {
      text-align: left;
      font-family: $font-lato;
      font-style: normal;
      font-weight: 700;
      font-size: $font-size-24;
      color: $text-black-color;
    }
   
    
  }

  .Button_style1 {
    width: 112px;
  }
  .Catoption .MuiInputBase-inputSizeSmall{
    font-family: $font-lato;
  }