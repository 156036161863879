@import "../../scss/variables";

.tableLoader_fullpage_ui {
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  z-index: 9;
  text-align: center;
  .MuiCircularProgress-root {
    position: absolute;
    z-index: 9;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    svg {
      color: $cerulean-color;
    }
  }
}

.fullpage_ui_loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;
  .MuiCircularProgress-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: auto;
    svg {
      color: $cerulean-color;
    }
  }
}
body.full_page_loader {
  overflow: hidden;
}

.inComponentLoader{
  svg {
    color: $cerulean-color;
  }
}