@import "../../../scss/variables";

.ImageTable {
  width: 100%;
  .TableImageThumb {
    width: 100px;
    max-height: 60px;
    height: auto;
    object-fit: cover;
  }

  .reportsTable {
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    font-family: $font-lato;
    font-size: 14px;
    line-height: 1.5;
    background-color: $white-color;
    border: 1px solid $gainsboro;

    tr {
      border-top: 1px solid $gainsboro;
    }
    th {
      padding: 15px 10px;
      text-align: left;
      font-size: $font-size-14;
      font-weight: 700;
      font-family: $font-lato;
      text-transform: none;
      background-color: $white-color;
      vertical-align: middle;
      background-color: #e4f0f5;
      &:nth-child(2) {
        width: 250px;
      }
    }
    td {
      padding: 15px 10px;
      font-size: $font-size-14;
      font-weight: 500;
      font-family: $font-lato;
      background-color: $white-color;
      vertical-align: middle;
    }
    .settingIconReport {
      width: 18px;
      height: 18px;
      display: block;
      color: $shadow-grey;
      cursor: pointer;
    }
  }
  .copyurl {
    cursor: pointer;
  }
  .CancelImage {
    cursor: pointer;
  }
}
