@import "../../../../scss/variables";

.overideProfile {
  .overideSelectMenu {
    .selectBox {
      background-color: $fog-grey;
    }
  }

  .rolesList{
    font-family: $font-lato;
    font-size: $font-size-16;
    color: $text-black-color;
    font-weight: 600;
  }

  .profileTitle {
    font-size: $font-size-24;
    font-weight: 600;
    color: $text-black-color;
    font-family: $font-lato;
  }

  .signatureBtn {
    border: 1px solid $cerulean-color;
    border-radius: 100px;
    color: $cerulean-color;
    width: 180px;
    text-transform: capitalize;
    display: block;
    text-align: center;
    padding: 8px 10px;
    font-family: $font-lato;
  }

  .topHeaderProfile {
    .profileImg {
      max-width: 180px;
      width: 100%;
      height: 180px;
      position: relative;

      .borderProfileImg {
        border-radius: 100%;
        border: 4px solid $cerulean-color;
        width: 100%;
        height: 100%;
      }

      .editProfileicon {
        width: 40px;
        height: 40px;
        border-radius: 100px;
        background-color: $cerulean-color;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 60px;
        right: -20px;
      }
    }

    .signatureImg {
      max-width: 220px;
      height: 180px;
      position: relative;

      .borderSignatureImg {
        border-radius: 0%;
        border: 4px solid #FFFFFF;
        ;
        width: 100%;
        height: 100%;
      }
      .signatureText {
        padding: 58px;
        font-family: $font-lato;
        font-weight: 700;
        font-size: $font-size-16;
        text-align: center;
        color:#9A9FA8;
        border-radius: 0%;
        border: 4px solid  #FFFFFF;
        width: 100%;
        height: 100%;
      }
    }

    .btnProfleBox {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      .btnProfile {
        min-width: 145px;
        height: 44px;
        background-color: $cerulean-color;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        cursor: pointer;
        span {
          color: $white-color;
          font-size: $font-size-16;
          font-weight: 700;
          font-family: $font-lato;

          img {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.etraingOver {
  .tableLoader_fullpage_ui {
    .MuiCircularProgress-root {
      top: 3%;
    }
  }
}

.MuiTabs-fixed .lessonList {
  text-transform: capitalize;
  font-weight: 700;
  font-family: $font-lato;
  font-style: normal;
  font-size: 16px;
}
