@import "../../scss/variables";

.MuiDialog-container {
  .modalPara {
    width: 100%;
    min-width: 320px;
    text-align: center;
    color: $shadow-grey;
    font-size: $font-size-14;
    font-weight: 400;
    font-family: $font-lato;
  }
}
.dialogBoxContainer {
  &.fullWidth {
    .MuiDialog-paper {
      max-width: 100%;
      width: 100%;
      .closeBox {
        text-align: right;
        position: relative;
        .closeIcon {
          cursor: pointer;
        }
      }
      .photoBox {
        width: 100%;
        .photoIMg {
          width: 100%;
          height: 250px;
          object-fit: cover;
        }
      }
      .nophoto {
        text-align: center;
        margin-top: 10px;
        font-size: $font-size-32;
        color: $light-primary-color;
        font-weight: 700;
        font-family: $font-lato;
        margin-bottom: 20px;
        width: 100%;
      }
    }
  }
  .MuiDialog-paper {
    border-radius: 15px;
    padding: 30px 27px 20px 30px;
    max-width: 400px;

    .deleteIcon {
      margin: auto;
    }
    .dialog-title {
      padding: 0px;
      text-align: center;
      font-size: $font-size-16;
      color: $text-black-color;
      font-weight: 700;
      font-family: $font-lato;
      margin: 10px 0px;
    }
    .modalParaContent {
      overflow: hidden;
      word-break: break-word;
      padding: 0px;
    }
    .dialogConfirm {
      padding: 0px;
      margin-top: 25px;
      .deleteyes {
        background-color: $nobg;
        width: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        color: $white-color;
        font-size: $font-size-14;
        font-weight: 600;
        font-family: $font-lato;
        padding: 0px;
        height: 40px;
        text-transform: capitalize;
      }
      .deleteno {
        background-color: $white-color;
        width: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: none;
        border-radius: 100px;
        color: $shadow-grey;
        font-size: $font-size-14;
        font-weight: 600;
        font-family: $font-lato;
        padding: 0px;
        height: 40px;
        text-transform: capitalize;
      }
    }
  }
}

.inc_warning {
  text-align: center;
  img {
    width: 80px;
    text-align: center;
  }
}
