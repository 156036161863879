@import "../../../../scss/variables";

.toolboxtalkView {
  border: 1px solid $gainsboro;
  border-radius: 8px;
  padding: 20px;
  .lessonImage {
    position: relative;
    .adminDetailBox {
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;

      .adminDetailList {
        background-color: $white-color;
        padding: 10px;
        margin-left: 15px;
        border-radius: 8px;

        .editText {
          font-size: $font-size-14;
          font-weight: 600;
          color: $text-black-color;
          font-family: $font-lato;
          .editIcon {
            width: 14px;
            height: 14px;
            margin-right: 10px;
          }
          .deleteHorIcon {
            width: 24px;
            height: 7px;
          }
        }
      }
    }
    img {
      border-radius: 10px;
      width: 100%;
      max-height: 500px;
      object-fit: cover;
    }
    .videoLink {
      border-radius: 10px;
      width: 100%;
      max-height: 500px;
      height: 500px;
    }
  }
  .imageDetail {
    margin-top: 25px;
    margin-bottom: 30px;
    display: flex;
    justify-content: flex-start;
    .imageDetailContent {
      margin-right: 40px;
      font-family: $font-lato;
      font-size: $font-size-14;
      color: $spanish-gray;
      font-weight: 600;
      .edittoolbox {
        margin-left: 70px;
        margin-right: 30px;
      }
      span {
        margin-right: 5px;
      }
      .count {
        font-family: $font-lato;
        font-size: $font-size-14;
        color: $text-black-color;
        font-weight: 600;
      }
    }
  }
  .assignBox {
    // background-color: $cerulean-color;
    padding: 5px 20px;
    max-width: 110px;
    margin: 20px 0 0 auto;
    border-radius: 8px;
    cursor: pointer;
    margin-right: 1rem;
    // .assignBoxBtn { TODO
    //   color: $white-color;
    //   font-size: $font-size-14;
    //   font-weight: 600;
    //   font-family: $font-lato;

    //   .assignImg {
    //     padding-right: 10px;
    //     margin-top: 2px;
    //   }
    // }
  }
  .title {
    font-size: $font-size-28;
    color: $text-black-color;
    font-weight: 700;
    font-family: $font-lato;
    text-align: left;
  }
  .otherDescration {
    margin-top: 30px;
    .subTitle {
      font-size: $font-size-20;
      color: $text-black-color;
      font-weight: 700;
      font-family: $font-lato;
      text-align: left;
      margin-bottom: 15px;
    }
    ul {
      text-align: left;
      li {
        margin-bottom: 25px;
        font-size: $font-size-18;
        color: $text-black-color;
        font-weight: 500;
        padding-left: 20px;
        position: relative;
        &::after {
          position: absolute;
          content: "";
          top: 12px;
          left: 0;
          background-color: $text-black-color;
          width: 5px;
          height: 5px;
          border-radius: 10px;
        }
      }
    }
    .desc {
      font-size: $font-size-16;
      color: $text-black-color;
      font-weight: 400;
      font-family: $font-lato;
      text-align: left;
      margin-bottom: 15px;
      line-height: 30px;
      strong {
        font-weight: bold;
        color: #333333;
      }
      em {
        font-style: italic;
      }
      ul {
        li {
          margin-bottom: 1px;
          // list-style: inside;
        }
      }
      // ul li {
      //   list-style: inside;
      // }
      h1 {
        font-size: 2em;
        font-weight: bold;
      }
      h2 {
        display: block;
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
      }
      h3 {
        display: block;
        font-size: 1.17em;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
      }
      h4 {
        display: block;
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
      }
      blockquote {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 40px;
        margin-inline-end: 40px;
      }
      ol {
        display: block;
        list-style-type: decimal;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
      }
      pre {
        display: block;
        font-family: monospace;
        white-space: pre;
        margin: 1em 0px;
      }
    }
    .pdfAttached {
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .pdfAttachedName {
        font-size: $font-size-14;
        color: $text-black-color;
        font-weight: 600;
        font-family: $font-lato;
        padding-left: 5px;
      }
    }
  }
  .lessonwithDetail {
    hr.solid {
      border-top: 1px solid #dde2ef;
      margin-bottom: 20px;
      margin-top: 10px;
    }

    .lessonListBox {
      .lessonList {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        min-height: 40px;
        border: 1px solid $gainsboro;
        border-radius: 4px;
        padding: 0px 10px;
        font-size: $font-size-14;
        color: $text-black-color;
        font-weight: 600;
        font-family: $font-lato;
        margin-bottom: 10px;
      }
    }
  }
  .lessonwithDetailBox {
    padding-left: 10px;
    .takeQuiz {
      text-align: left;
      margin-top: 30px;
      .containedButton {
        max-width: 140px;
        padding: 0px 20px;
      }
    }
    .checkingBox {
      .checkboxs {
        .closeItem {
          font-family: $font-lato;
          font-weight: 400;
          font-size: $font-size-14;
          color: $spanish-gray;
          margin-left: 8px;
        }
        .tickbox {
          position: relative;
        }
      }
    }
    .Btncomplete {
      text-align: left;
      margin-top: 30px;
      .containedButton {
        max-width: 300px;
        padding: 0px 20px;
      }
    }
  }
}
.deleteText {
  font-size: $font-size-14;
  font-weight: 400;
  color: $text-black-color;
  font-family: $font-lato;
  padding: 10px;
}
.trngComp {
  text-align: center;
  margin-left: 4rem;
  margin-top: 11px;
  font-weight: 700;
  text-transform: capitalize;
  font-family: $font-lato;

  color: #34a853;
}
.assignPopBox {
  .MuiPaper-rounded {
    .assignPopDetail {
      padding: 20px;
      width: 384px;
      height: 282px;
    }
    .innerauto {
      padding: 10px;
    }
  }
}
.imageDetailContentTool {
  border: #d9d9d9 solid;
  border-radius: 24px;
  width: 79px;
  height: 17px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  align-items: center;

  color: #333333;
  //background-color: red;
}
.edittoolbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  position: absolute;
  width: 132.63px;
  height: 36.48px;
  // margin-left: 65px;
  // margin-right: 35px;
}
.edittoolboxDot {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: flex-start;
  padding: 1px 10px 10px 10px;
  gap: 10px;

  position: absolute;
  height: 35.68px;
  width: 35.68px;
  margin-left: 100px;

  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}
.edittoolboxDot span {
  flex: none;
  order: 0;
  flex-grow: 0;
  text-align: center;
  justify-content: center;
}
