@import "../../../scss/variables";

.overidePersonal {
  .addBox {
    text-align: left;
    margin: 15px 0px;
    width: 100%;
  }
  .addBtn {
    color: $green-color;
    font-weight: 600;
    font-size: $font-size-14;
    justify-content: flex-start;
    &:hover {
      text-decoration: underline;
    }
  }
  .addIconBtn {
    color: $green-color;
  }
  .labelCommon {
    font-family: $font-lato;
    font-style: normal;
    font-weight: 600;
    font-size: $font-size-16;
    line-height: 19px;
    text-align: left;
    margin-bottom: 10px;
    color: $text-black-color;
  }
  
  .associatedIncidentLabel {
    font-family: $font-lato;
    font-style: normal;
    font-weight: 600;
    font-size: $font-size-16;
    line-height: 19px;
    text-align: left;
    margin-bottom: 10px;
    color: $shadow-grey;
  }
  .contractCardBody {
    background-color: #e1e5e8;
    padding: "30px 16px";
    border-radius: 10px;
    text-align: left;
  }
  .contractSubHead {
    margin-bottom: 13px;
    font-family: $font-lato;
    font-style: normal;
    font-weight: 500;
    font-size: $font-size-16;
    line-height: 20px;
  }
  .colorRed {
    font-family: $font-lato;
    font-style: normal;
    font-weight: 400;
    font-size: $font-size-12;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: $red-color;
    margin-top: 7px;
  }
  .colorGreen {
    font-family: $font-lato;
    font-style: normal;
    font-weight: "400";
    font-size: $font-size-12;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: $green-color;
    margin-top: 7px;
  }
  .checked {
    margin-left: 2px;
  }
  .typeTxt {
    margin-bottom: 10px;
    span {
      padding-left: 0px;
    }
    .checkImage {
      // margin-left: -16px;
      margin-right: 13px;
      cursor: pointer;
    }
  }
  .ContactMsgBox {
    text-align: left;
  }
  .conatctMsgRigt {
    .contactMsg {
      background-color: $text-black-color;
      color: $white-color;
      padding: 10px;
      font-size: $font-size-16;
      border-radius: 4px;
      margin-top: 30px;
      position: relative;
      &::after {
        content: "";
        border-style: solid;
        border-width: 10px 15px 10px 0;
        border-color: transparent $text-black-color transparent transparent;
        position: absolute;
        left: -15px;
      }
    }
  }
}

[data-theme="dark"] {
  .overidePersonal {
    .labelCommon {
      color: $white-color;
    }
    .css-i4bv87-MuiSvgIcon-root {
      color: $white-color;
    }
    .addIconBtn {
      color: $green-color;
    }
  }
}
