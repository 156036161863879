@import "../../scss/variables";

.login {
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: $white-color;
  &-container {
    margin: 20px;
    margin-top: 50px;
    @media screen and (min-width: 768px) {
      display: flex;
      padding-top: 5%;
      margin-top: 0px;
      justify-content: space-between;
    }
  }
  &-left {
    @media screen and (min-width: 768px) {
      max-width: 340px;
      width: 100%;
      margin-left: 4%;
      text-align: left;
    }
    .logoDark {
      display: none;
    }
    .logoLight {
      display: block;
    }
    .logo {
      width: 170px;
      max-width: 100%;
    }

    .logo-title {
      font-weight: 700;
      font-size: $font-size-30;
      line-height: 36px;
      color: $text-black-color;
      margin-top: 30px;
      font-family: $font-lato;
    }
    .form-wrapper {
      .input-control {
        background-color: $white-color;
        border: 1px solid $Stroke-Fossil-Grey;
        &.border-red {
          border: 1px solid $red-color;
        }
      }
      .showHidePassword {
        filter: initial;
        width: 20px;
        height: 20px;
      }
      .label {
        font-weight: 600;
      }
    }
  }

  &-right {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 4%;
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  &-button {
    margin-top: 20px;
  }
}

.forgot-password {
  font-weight: 600;
  font-size: $font-size-14;
  line-height: 20px;
  text-align: right;
  color: $cerulean-color;
  margin-top: 20px;
  cursor: pointer;
}

.login-button {
  text-align: left;
  button {
    max-width: 340px;
    width: 100%;
    height: 44px;
    line-height: 44px;
    padding: 0;
    font-weight: 500;
  }
}
.signup-txt {
  span {
    cursor: pointer;
    font-weight: 900;
  }
}
// span {
//   text-decoration: underline;
// }

.password-container {
  position: relative;
  img {
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;
    width: 20px;
  }
}
.signup-txt {
  font-weight: 600;
  font-size: $font-size-14;
  color: $spanish-gray;
  text-align: left;
  margin-top: 14px;
  span {
    color: $cerulean-color;
    font-size: $font-size-14;
    font-weight: 900;
    text-decoration: none;
  }
}

[data-theme="dark"] {
  .login {
    background-color: $black-color;
    &-left {
      .logo-title {
        color: $white-color;
      }
      .logoDark {
        display: block;
      }
      .logoLight {
        display: none;
      }
      .signup-txt {
        color: $shadow-grey;
      }
      .form-wrapper {
        .input-control {
          background-color: $space-grey-color;
          color: $white-color;
          &.border-red {
            border: 1px solid $red-color;
          }
        }
        .showHidePassword {
          filter: brightness(30);
        }
      }
    }
  }
}
