@import "../../../../scss/variables";

.overCreateQuiz {
  text-align: left;
  
  .MuiInputBase-formControl{
    font-family: $font-lato;
  }
  .quizBtnContainer {
    margin-top: 50px;
    .quizBack {
      .backBtn {
        margin-top: 10px;
        .backTitle {
          font-size: $font-size-16;
          color: $text-black-color;
        
        }
      }
    }
    .quizSubmit {
      text-align: right;
      .overideBtn {
        .containedButton {
          max-width: 176px;
          font-family: $font-lato;
        }
      }
    }
  }
}
