@import "./variables";

.form-wrapper-row {
  display: grid;
  grid-gap: 17px;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.form-wrapper {
  margin-top: 20px;
  text-align: left;

  &.has-error {
    h1 {
      color: $red-color;
    }
  }

  h1 {
    font-weight: 600;
    font-size: $font-size-16;
    color: $shadow-grey;
  }

  label {
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: $font-size-16;
    line-height: 17px;
    color: $shadow-grey;
    text-align: left;
    display: block;
  }

  input {
    border-radius: 15px;
    padding: 10px 15px;
    background-color: $white-color;
    font-size: 14px;
    background: #ffffff;
    border: 1px solid $Stroke-Fossil-Grey;
    width: 100%;
    height: 44px;
    line-height: 44px;
  }

  // input[type="email"] {
  //   width: 450px;
  // }
}

.show-error {
  gap: 3px;
  width: 100%;
  color: $red-color;
  display: flex;
  margin-top: 6px;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  &:before {
    width: 13px;
    content: "";
    height: 13px;
    background: url("../assets/form-error.svg") no-repeat;
    background-size: contain;
  }
}

.success {
  gap: 3px;
  width: 100%;
  color: $green-color;
  display: flex;
  margin-top: 6px;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  &:before {
    width: 13px;
    content: "";
    height: 13px;
    background: url("../assets/success.svg") no-repeat;
    background-size: contain;
  }
}

// disable spin button in safari
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.verify-otp {
  font-style: normal;
  font-weight: 400;
  font-size: $font-size-22;
  line-height: 26px;
  color: $shadow-grey;
}

.card {
  height: 91px;
  width: 297px;
  position: relative;
  display: flex;
  margin: 20px 5px;

  @media screen and (max-width: 767px) {
    width: 90%;
    height: 80px;
  }
}
// input[type="radio"] {
//   -webkit-appearance: none;
//   appearance: none;
//   background-color: white;
//   height: 100%;
//   width: 100%;
//   border-radius: 10px;
//   position: absolute;
//   box-shadow: 7px 7px 15px rgba(2, 28, 53, 0.08);
//   cursor: pointer;
//   outline: none;
//   border: 1px solid #dfdfdf;
// }

.card-div {
  position: absolute;
  margin: 13px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;

  img {
    @media screen and (max-width: 767px) {
      width: 25px;
    }
  }

  h1 {
    font-family: $font-lato;
    font-style: normal;
    font-weight: 600;
    font-size: $font-size-18;
    line-height: 18px;
    margin: 15px 0 20px 0;
    color: $shadow-grey;
    margin-left: 20px;
    max-width: 70%;
    @media screen and (max-width: 767px) {
      font-size: 10px;
      margin-left: 10px;
    }
  }
}

span {
  font-weight: 400;
  font-size: $font-size-18;
  color: #7b7b93;
}
// input[type="radio"]:hover {
//   transform: scale(1.05);
// }
// input[type="radio"]:checked {
//   border: 3px solid #15cf5f;
//   transition:
//   background 0.2s ease-out,
//   border-color 0.2s ease-out;
// }
// input[type="radio"]:checked:after {
//   background-color: #15cf5f;
// }
.cardss {
  display: none;
}
.card-input:hover {
  cursor: pointer;
}
.card-input-element:checked + .card-input {
  box-shadow: 0 0 1px 1px #2ecc71;
}

/***********For mobile country code***************/

#mobile {
  padding-left: 45px;
}
.initial {
  position: relative;
}

.initial::after {
  position: absolute;
  left: 10px;
  top: 50%;
  content: attr(data-placeholder);
  pointer-events: none;
  opacity: 0.6;
  transform: translate(0%, -50%);
}

/***********For Radio button***************/
.radio-container {
  display: flex;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
}
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.radio-container input:checked ~ .checkmark {
  background-color: $white-color;
  border: 1px solid $cerulean-color;
}
.radio-container input:checked ~ .checkmark:after {
  display: block;
}
.radio-container .checkmark:after {
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: $white-color;
}
.radio-container .checkmark {
  position: absolute;
  top: 0;
  left: 4px;
  height: 20px;
  width: 20px;
  border: 1px solid #dfdfdf;
  border-radius: 50%;
}
.radio-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

[data-theme="dark"] {
  .form-wrapper {
    label {
      color: $white-color;
    }
  }
}
