@import "../../scss/variables";

.overirdeTerms {
  .light-logo {
    margin: 20px;
    width: 300px;
  }
  .flex {
    display: flex;
    flex-wrap: wrap;
  }
  .container {
    background: #1b436e;
    padding: 20px;
    h1 {
      font-family: $font-lato;
    }
    .sup {
      vertical-align: super;
      font-size: 8px;
      font-family: $font-lato;
      font-weight: 700;
    }
    .name {
      font-size: 30px;
      color: #fff;
      font-family: $font-lato;
    }
    .service {
      color: #ccc;
      font-weight: 700;
      font-size: 30px;
      font-family: $font-lato;
    }
    .termsGrid {
      background: #fff;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding-bottom: 40px;
      .termsHeader {
        text-align: center;
        font-weight: 900;
        font-family: $font-lato;
        margin-top: 40px;
      }
      ol {
        font-weight: 500;
        color: $text-black-color;
        font-size: $font-size-16;
        li {
          text-align: left;
          margin-top: 40px;
          font-family: $font-lato;
          color: $text-black-color;
          // font-size: $font-size-16;
          span {
            font-weight: 500;
            color: $text-black-color;
          }
        }
      }
    }
    .copyrightGrid {
      background: #666;
      padding: 15px;
      display: inline-block;
      width: 100%;
      span {
        color: #fff;
        float: right;
        font-family: $font-lato;
      }
    }
  }
  span {
    text-decoration: underline;
  }
}
