@import "../../../../scss/variables";

.swins {
  &.isActiveBox {
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    .cursorDefault{
      cursor: default;
      .cursorActive{
        cursor: pointer;
      }
    }
  }
}


.swQuestionBox {
  &.swQuestionBoxQuestion {
    padding: 20px;
    padding-top: 0px;
  }
  background-color: $white-color;
  box-shadow: 0px 4px 90px rgba(0, 0, 0, 0.1);
  padding: 40px;
  margin-top: 30px;
  .addQuestionSW {
    color: $text-black-color;
    font-size: $font-size-16;
    font-weight: 600;
    font-family: $font-lato;
    cursor: pointer;
    width: max-content;
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }
  .questionContainerSW {
    margin-top: 20px;
    .questionBoxListSw {
      border: 1px solid $light-silver;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .questionBoxSw {
        padding: 0px;
        width: 100%;
        .overideInputField {
          .MuiFormHelperText-root {
            bottom: -90px;
          }
        }
        &.withPadding {
          padding: 10px;
        }
        .overideInputField {
          .input_field_box {
            .formControlRoot {
              border: none;
            }
          }
        }
      }
      .swInput {
        width: 100%;
        height: 44px;
        border: none;
        padding: 0px 20px;
        font-size: $font-size-16;
        font-weight: 500;
        font-family: $font-lato;
        color: $spanish-gray;
        &::placeholder {
          color: $spanish-gray;
        }
        &:focus {
          outline: none;
          border: none;
          box-shadow: non;
        }
      }
      .questionCloseBoxSw {
        width: 130px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $shadow-grey;
        font-size: $font-size-16;
        font-weight: 500;
      }
    }
  }
  .responseTypeBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $fog-grey;
    padding: 7px 20px;
    .responseType {
      font-size: $font-size-16;
      color: $shadow-grey;
      font-weight: 600;
      font-family: $font-lato;
    }
    .responseTypeRight {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .responseTypeBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
        background-color: $white-color;
        min-width: 105px;
        padding: 5px 10px;
        border: 1px solid $sonic-silver;
        border-radius: 5px;
        font-size: $font-size-14;
        color: $cerulean-color;
        cursor: pointer;
        &.active {
          background-color: $cerulean-color;
          color: $white-color;
        }
      }
    }
  }
}

.insBackBtn {
  &.backSW {
    .backBtn {
      margin: 10px 0px;
    }
  }
}
.insTyF {
  display: flex;
  align-items: center;
  .insTyFLeft {
    width: 70%;
    color: $shadow-grey;
    font-size: $font-size-16;
    font-weight: 600;
  }
  .insTyFRight {
    width: 30%;
    color: $shadow-grey;
    font-size: $font-size-16;
    font-weight: 600;
  }
}

.insTyBox {
  margin-top: 20px;
  .insTyList {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    .insTyFLeft {
      width: 70%;
      .check {
        .closeItem {
          font-size: $font-size-14;
          font-weight: 500;
        }
      }
    }
    .insTyFRight {
      width: 30%;
    }
  }
}

.insGenBt {
  .insBackBtn {
    .backBtn {
      margin: 10px 0px;
    }
  }
}

.responseTypeBoxSet {
  .responseTypeBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $fog-grey;
    padding: 7px 20px;
    .responseType {
      font-size: $font-size-16;
      color: $shadow-grey;
      font-weight: 600;
      font-family: $font-lato;
    }
    .responseTypeRight {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .responseTypeBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
        background-color: $white-color;
        min-width: 105px;
        padding: 5px 10px;
        border: 1px solid $sonic-silver;
        border-radius: 5px;
        font-size: $font-size-14;
        color: $cerulean-color;
        cursor: pointer;
        &.active {
          background-color: $cerulean-color;
          color: $white-color;
        }
      }
    }
  }
}
