@import "../../../../scss/variables";

.appendixOver {
  margin-top: 40px;

  .appendixBox {
    background: $white-color;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    padding: 12px 25px 16px 25px;
    text-align: left;
    .appendixtitle {
      font-size: $font-size-20;
      color: $cerulean-color;
      font-weight: 700;
      font-family: $font-lato;
      margin-bottom: 13px;
    }
  }
}
