@import "../../../scss/variables";

.filterResult {
  background: $white-color;
  box-shadow: 0px 4px 90px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 13px;
  min-width: 323px;
  max-width: 323px;
  width: 100%;
  .filterTitle {
    margin-bottom: 15px;
    font-size: $font-size-16;
    color: $shadow-grey;
    font-family: $font-lato;
    // text-transform: uppercase;
    font-weight: 600;
  }
  .checkboxWrap {
    .labelList {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0px 10px;
      .checkboxText {
        width: 48%;
        margin-bottom: 15px;
        span {
          padding: 0px;
          &.MuiFormControlLabel-label {
            font-size: $font-size-14;
            font-weight: 400;
            color: $text-black-color;
            font-family: $font-lato;
            margin-left: 5px;
          }
          .iconuncheck {
            color: $spanish-gray;
            font-size: $font-size-18;
          }
          .iconcheck {
            color: $cerulean-color;
            font-size: $font-size-18;
          }
        }
      }
    }
  }
  .filterBottom {
    text-align: end;
    padding-top: 15px;
    border-top: 0.5px solid $gainsboro;
  }
}

.filterResult:hover {
  cursor: pointer;
}
