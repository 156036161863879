@import "../../../scss/variables";

.overrideConnect {
  .connectMainContainer {
    display: flex;
    width: 100%;
    .rightContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      .cardsContainer {
        display: flex;
        width: 100%;
        justify-content: space-around;
        .card {
          min-width: 250px;
        }
        .icon {
          width: 60px;
          height: 60px;
          color: $cerulean-color;
        }
      }
    }
  }
}
