@import "../../scss/variables";

.check {
  .tickbox {
    width: 20px;
    height: 20px;
    border: 2px solid $gainsboro;
    border-radius: 5px;
    cursor: pointer;
    accent-color: $cerulean-color;
    position: absolute;
  }
  .closeItem {
    font-family: $font-lato;
    font-style: normal;
    font-weight: 600;
    font-size: $font-size-16;
    color: $shadow-grey;
    margin-left: 36px;
    margin-top: 5px;
    position: absolute;
  }
}

.sw {
  margin-top: 5px;
  .check {
    .tickbox {
      width: 14px;
      height: 14px;
    }
    .closeItem {
      font-weight: 400;
      font-size: $font-size-12;
      margin-left: 25px;
    }
  }
}
.swProject {
  .check {
    .tickbox {
      width: 16px;
      height: 16px;
    }
    .closeItem {
      font-weight: 400;
      font-size: $font-size-12;
      margin-left: 25px;
    }
  }
}
