$desktop-width: 768px;

@mixin mobile {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

$font-lato: "Lato";
$Bright-Gray: #e4f0f5;
$Stroke-Fossil-Grey: #dde2e5;
$white-color: #fff;
$black-color: #1f1f1f;
$cerulean-color: #1b436e;
$steel-blue-color: #2f709a;
$concrete-color: #cdd5df;
$smoke-color: #edf0f4;
$sky-grey-color: #e4f0f5;
$royal-blue-color: #004ddd;
$saphire-blue-color: #195be1;
$davy-grey-color: #2e2c31;
$space-grey-color: #34323a;
$text-black-color: #333333;
$red-color: #f21d1d;
$manatee-color: #9a9fa8;
$fossil-grey: #dde2e5;
$fog-grey: #eeeeee;
$shadow-grey: #666666;
$border-grey: #bcb9be;
$green-color: #34a853;
$gainsboro: #dfdfdf;
$spanish-gray: #999999;
$malachite: #15cf5f;
$chinese-silver: #cacaca;
$apple-red: #ab0000;
$ocean-green: #c44585;
$philippine-silver: #b8b8b8;
$valid-color: #45c484;
$giants-orange: #ff5122;
$cultured: #f5f5f5;
$chinese-white: #e0e0e0;
$nobg: #d30d25;
$stolen-bg: #ea4335;
$notApplicable: #f0b03e;
$inprogress: #f99746;
$deepveer: #dfe0eb;
$approx-snow: #fbfbfb;
$light-silver: #d9d9d9;
$answer-select: #c6c6c6;
$sonic-silver: #797e74;
$rosemadder: #ed1b2f;
$boulder: #79747e;
$gray: #808080;
$dawn-pink: #EBEBEB;

$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-26: 26px;
$font-size-28: 28px;
$font-size-30: 30px;
$font-size-32: 32px;
$font-size-34: 34px;
$font-size-36: 36px;
$font-size-38: 38px;
$font-size-40: 40px;
$font-size-42: 42px;
$font-size-64: 64px;

$light-primary-color: #1b436e;
$light-primary-background-color: #fff;
$light-primary-text-color: #1f1f1f;

$dark-primary-color: #004ddd;
$dark-secondary-color: #195be1;
$dark-primary-background-color: #1f1f1f;
$dark-secondary-background-color: #333333;
$dark-primary-text-color: #fff;
$dark-tertiary-background-color: #212121;
$dark-grid-hover-color: #000000;
$dark-form-all-input-color: #34323a;
$dark-border: #79747e;