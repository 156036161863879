@import "../../../scss/variables";

.overideSelectMenu {
  font-family: $font-lato;
  .selectBox {
    color: $text-black-color;
    line-height: 19px;
    letter-spacing: 0px;
    font-family: $font-lato;
    font-weight: 600;
    font-size: $font-size-16;
    width: 100%;
    margin: 0px;
    border-radius: 15px;
    text-align: left;
    height: 44px;
    padding: 0px 10px;
    color: $text-black-color;
    text-transform: capitalize;
    &.Mui-disabled {
      background-color: $fog-grey;
    }
  }

  .expicon {
    font-size: 30;
    color: #999999;
  }
  svg:hover {
    cursor: pointer;
  }

  .errorText {
    color: $red-color;
    margin-left: 0px;
  }
  .placeholder {
    font-family: "Lato";
    font-weight: 500;
    color: #666666;
  }
}

span {
  text-decoration: none;
}
.multiCheck {
  margin-right: 7px;
}

[data-theme="dark"] {
  .overideSelectMenu {
    .selectBox {
      background-color: $space-grey-color;
      border: 1px solid $fossil-grey;
      color: $white-color;
    }
  }
}
.MuiMenu-list .selectutilityitems {
  font-family: $font-lato;
  text-transform: none;
  &.dropCustom {
    text-transform: none;
  }
}

.MuiButtonBase-root.MuiMenuItem-root:hover {
  background-color: $cerulean-color !important;
  color: $white-color !important;
}

.selectutilityitems {
  .MuiListItemText-root {
    .MuiTypography-root:hover {
      color: $white-color;
    }
  }
}

.selecticonCls {
  position: absolute;
  right: 0;
  top: -3px;
  pointer-events: none;
}

.SelectMenuValue {
  .overideSelectMenu {
    .MuiSelect-select {
      text-transform: none;
    }
  }
}
.SelectMenuValue {
  .overideSelectMenu {
    .MuiSelect-select {
      .placeholder {
        text-transform: capitalize;
      }
    }
  }
}
