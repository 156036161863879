@import "../../../scss/variables";
.reportBody {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 18px;
  max-width: 70%;
  margin-bottom: 40px;
  margin-top: 40px;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
    gap: 0;
  }
}
.reportCardBody {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  width: 30%;
  padding: 24px;
  border-radius: 8px;
  cursor: pointer;
  &.active {
    border: 3px solid $cerulean-color;
  }
  .number {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 77px;
    text-align: center;
  }
  &:nth-child(1) {
    .number {
      color: #1b436e;
    }
  }
  &:nth-child(2) {
    .number {
      color: #f0b03e;
    }
  }
  &:nth-child(3) {
    .number {
      color: #f21d1d;
    }
  }
  &:nth-child(4) {
    .number {
      color: #1b436e;
    }
  }
  &:nth-child(5) {
    .number {
      color: #34a853;
    }
  }
  &:nth-child(6) {
    .number {
      color: #d30d25;
    }
  }

  @media (max-width: 767px) {
    width: 50%;
    margin-bottom: 20px;
  }
  @media (max-width: 460px) {
    width: 48%;
    margin-bottom: 20px;
  }

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
}

.text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 4px 0px;
  color: #333333;
  text-align: center;
}
.lmsReport {
  .overideBtn {
    text-align: right;
    .primary_btn_style {
      background-color: $green-color;
      border-color: $green-color;
      border: 1px solid;
    }
  }
  .newCards {
    .blueCardBody {
      .staticDataCard {
        &:last-child {
          background-color: $nobg;
          color: $white-color;
          border-color: $nobg;
        }
      }
    }
  }
}

.reportChart {
  .pieGraphCardBody {
    background-color: $white-color;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 30px;
    padding: 10px 10px;
    margin-top: 50px;
    border-radius: 15px;
    border: 1px solid transparent;
    cursor: pointer;
    &.active {
      border: 1px solid $cerulean-color;
    }
    .heading {
      color: $text-black-color;
      font-size: $font-size-16;
      font-weight: 500;
      font-family: $font-lato;
      margin-bottom: 0px;
    }
    .addedNumber {
      color: $cerulean-color;
      font-size: $font-size-30;
      font-weight: 500;
      font-family: $font-lato;
    }
  }
}
.reportTable {
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  font-family: $font-lato;
  font-size: 14px;
  line-height: 1.5;
  background-color: $white-color;
  border: 1px solid $gainsboro;

  tr {
    border-top: 1px solid $gainsboro;
  }
  th {
    padding: 15px 10px;
    text-align: left;
    font-size: $font-size-14;
    font-weight: 700;
    font-family: $font-lato;
    text-transform: uppercase;
    background-color: $white-color;
    vertical-align: middle;
    &:nth-child(2) {
      width: 150px;
    }
  }
  td {
    padding: 15px 10px;
    font-size: $font-size-14;
    font-weight: 500;
    font-family: $font-lato;
    background-color: $white-color;
    vertical-align: middle;
  }
  .settingIconReport {
    width: 18px;
    height: 18px;
    display: block;
    color: $shadow-grey;
    cursor: pointer;
  }
}

@media only screen and (max-width: 600px) {
  .reportTableRes {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    .reportTable {
      min-width: 900px;
    }
  }
  .reportTable {
    font-size: 12px;
    th,
    td {
      padding: 4px;
    }
  }
}
.downloadList {
  a {
    color: $text-black-color;
    font-size: $font-size-14;
    font-family: $font-lato;
    font-weight: 500;
  }
}

@media print {
  .reportChart {
    .pieGraphCardBody {
      margin-top: 0px;
    }
  }
  .lmsReport {
    .overideBtn {
      .primary_btn_style {
        display: none;
      }
    }
    canvas {
      max-width: 200px !important;
      max-height: 200px !important;
    }
  }
}
