@import "../../scss/variables";

.nameBox {
  display: flex;
  align-items: center;
  .nameBoxpara {
    font-family: $font-lato;
    font-weight: 600;
    font-size: $font-size-16;
    color: $text-black-color;
  }
}
