@import "../src/scss/variables";

html,
body {
  font-family: $font-lato !important;
  color: $text-black-color;
}

.App {
  text-align: center;
}

.container {
  max-width: 1500px;
}

a {
  color: $cerulean-color;
}

a:hover {
  color: $cerulean-color;
}
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}
.blackText {
  color: $text-black-color;
}
.error {
  color: $red-color;
  font-size: $font-size-14;
  margin-top: 5px;
}
.errornot {
  color: $black-color;
  font-size: $font-size-14;
  margin-top: 5px;
}
.MuiFormHelperText-root {
  color: $red-color !important;
}
.MuiDataGrid-columnHeaders {
  background-color: $Bright-Gray;
}
//Test
.dashboard {
  .contentContainer {
    display: flex;
    justify-content: space-between;
    .rightBar {
      width: 100%;
      padding-left: 320px;
      @media (max-width: 1180px) {
        padding-left: 0px;
      }
      @media (max-width: 1023px) {
        padding-left: 0px;
      }
      @media (max-width: 767px) {
        padding-left: 0px;
      }
      .rightBar {
        padding-left: 0px;
      }
      .container_wrapper {
        padding: 20px;
        margin-left: 20px;
        min-height: 100vh;
        @media (max-width: 1023px) {
          padding: 0px 20px 20px 20px;
          margin-left: 0px;
        }
        @media (max-width: 767px) {
          margin-left: 0px;
          padding: 0px 20px 20px 20px;
        }
        .dashTitle {
          font-size: $font-size-24;
          color: $text-black-color;
          font-weight: 700;
          font-family: $font-lato;
          margin-bottom: 50px;
        }
        .addShadowBox {
          box-shadow: 0px 4px 50px rgb(0 0 0 / 7%);
          border-radius: 8px;
          padding: 20px;
          margin-top: 40px;
        }
      }
    }
  }
}
.pl-0 {
  padding-left: 0px !important;
}
.css-13r4j3i .MuiTextField-root {
  margin: 0px !important;
  width: 100% !important;
}

// grid
.grid_main_sec {
  text-align: left;
  margin-top: 40px;
  .grid_main_header {
    margin-bottom: 15px;
    .grid_heading_style {
      font-family: $font-lato;
      font-weight: 700;
      font-size: $font-size-22;
      line-height: 29px;
    }
  }
  .two_grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .grid_main_body {
    padding: 0px;
    margin: 0px;
  }
}
.grid_main_sec:first-child {
  margin-top: 0px;
}
.dateborderNone {
  .overideDatePicker {
    .Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: $Stroke-Fossil-Grey;
      }
    }
  }
}

// dashboard design
.flex_main {
  .Sidebar {
    //position: absolute;
    z-index: 9999;
    left: 0;
    top: 0;
  }
  .footer_sec {
    border-top: 1px solid $fog-grey;
    margin: 0px -15px 0px -30px;
  }
  .container {
    max-width: calc(100% - 320px);
    flex: 0 0 calc(100% - 320px);
    padding-left: 30px;
    padding-right: 15px;

    .container_wrapper {
      padding-left: 0px;
      min-height: calc(100% - 70px);
      .row_uniq {
        margin-top: 30px;
      }

      .row_uniq:nth-child(1) {
        margin-top: 0px;
      }
      .section_box {
        max-width: 530px;

        .viewcasetitile {
          left: 0;
          margin: 0;
          position: inherit;
          width: auto;
          height: auto;
          text-align: left;
          padding-bottom: 10px;
        }
      }
      .desc_box {
        .viewinctitle {
          position: inherit;
          left: 0;
          top: 0;
          margin: 0;
          padding: 0;
          width: auto;
          height: auto;
          padding-bottom: 10px;
          text-align: left;
        }
      }
      // table style
      .table_style_ui {
        .MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight {
          border: none;
          display: none;
        }
        .MuiDataGrid-columnHeaderTitleContainerContent {
          .MuiDataGrid-columnHeaderTitle {
            font-weight: 700;
          }
        }
        .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
          color: $border-grey;
        }
        .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
          color: $cerulean-color;
        }

        .MuiDataGrid-root.MuiDataGrid-root--densityStandard {
          border: none;
          .MuiDataGrid-main {
            border: 1px solid $border-grey;
            margin-bottom: 30px;
          }
        }
        .MuiDataGrid-footerContainer {
          border: none;
          position: relative;
          top: -30px;
        }
      }
    }
  }
}
[data-theme="dark"] {
  background-color: $black-color;
  min-height: 100%;
  .dashboard {
    .contentContainer {
      .rightBar {
        .container_wrapper {
          padding: 20px;
          background: $davy-grey-color;
          box-shadow: 0px 4px 90px rgb(0 0 0 / 10%);
          margin-left: 20px;
        }
      }
    }
  }
  .table_style_ui {
    .css-1kwdphh-MuiDataGrid-virtualScrollerContent {
      color: $white-color;
    }
    .MuiIconButton-root {
      color: $white-color;
    }
    .MuiTablePagination-displayedRows {
      color: $white-color;
    }
    .MuiDataGrid-iconSeparator {
      display: none;
    }
  }
}

.logo,
.logos {
  img {
    min-width: 170px;
  }
}

.table_style_ui {
  .MuiDataGrid-virtualScrollerRenderZone {
    .MuiDataGrid-row {
      .MuiDataGrid-cell {
        &:last-child {
          justify-content: flex-end;
        }
      }
    }
  }
}

.table_style_ui {
  height: 430px;
  width: 100%;
  .overrideDataGrid {
    .MuiDataGrid-columnHeaders {
      .MuiDataGrid-columnHeadersInner {
        .MuiDataGrid-columnHeader {
          &:last-child {
            .MuiDataGrid-columnHeaderDraggableContainer {
              .MuiDataGrid-columnHeaderTitleContainer {
                justify-content: flex-end;
                // padding-right: 20px;
              }
            }
          }
        }
      }
    }
  }
}
.fixed_header {
  .container_wrapper {
    .header-over {
      .grid_main_sec {
        position: fixed;
        width: 70%;
      }
    }
  }
}

.openItemoverride {
  .insi-stitle {
    font-family: "Lato";
    font-weight: 700;
    font-size: 24px;
    color: #333333;
    margin-bottom: 50px;
    margin-left: 20px;
  }
}
.overRideOpenItem {
  width: 100%;
  display: flex;
  align-items: inline;
  display: inline-flex;

  .pieGraphCardBody {
    width: 100%;
    width: 300px;
    height: 300px;
    border-radius: 15px;
    background: #ffffff;
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
    .assignedDiv1 {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      margin-left: 12px;
      margin-top: 6px;
      .heading {
        font-family: $font-lato;
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        opacity: 0.7;
      }
      .addedNumber {
        font-family: $font-lato;
        font-weight: 700;
        font-size: 16px;
        color: #1b436e;
        position: absolute;
        margin-top: 30px;
        margin-left: 5px;
      }
    }
    .pieChart {
      width: 226px;
      height: 226px;
      margin-left: 37px;
    }
  }
  .box {
    display: flex;
    flex-direction: row;
    .grid1 {
      width: 100%;
      display: flex;
      flex-direction: column;
      &:focus {
        border: 2px solid black;
      }
    }
    .grid2 {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .openItemCard {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 140px;
    height: 140px;
    background: #ffffff;
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    cursor: pointer;
    &.active {
      border: 3px solid $cerulean-color;
    }
    &:hover {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }
    margin-top: 14px;
    margin-left: 66px;
    .Itemgroup {
      display: flex;
    }
  }
  .headings {
    font-family: $font-lato;
    font-weight: 600;
    font-size: $font-size-16;
    line-height: 19px;
    color: #666666;
    margin-left: 7px;
  }
  .value {
    font-family: $font-lato;
    font-weight: 700;
    font-size: $font-size-40;
    color: #1b436e;
  }
}

.innerTabAssets .MuiTabs-fixed [aria-controls="5"].lessonList {
  display: none;
}

.innerTabAssets .MuiTabs-fixed [aria-controls="6"].lessonList {
  display: none;
}

.innerTabAssets .MuiTabs-fixed [aria-controls="4"].lessonList {
  display: none;
}
.radioGroup {
  .radioColor {
    span {
      &.MuiTypography-root {
        font-family: $font-lato;
        color: #666666;
        text-decoration: none;
      }
    }
    .MuiRadio-colorPrimary {
      &.Mui-checked {
        &.Mui-disabled {
          span {
            svg {
              color: $gainsboro;
            }
          }
        }
        span {
          svg {
            color: $cerulean-color;
          }
        }
      }
    }
  }
}

.inprogressStatus {
  background-color: #ffa413;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-bottom: 10px;
  border-radius: 20px;
  font-weight: 600;
}
.closeStatus {
  background-color: #35a853;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-bottom: 10px;
  border-radius: 20px;
  font-weight: 600;
}
.openStatus {
  background-color: $nobg;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-bottom: 10px;
  border-radius: 20px;
  font-weight: 600;
}
.myOpenItem {
  margin-left: 20px;
  .myOpenItemTitle {
    font-family: "Lato";
    font-weight: 700;
    font-size: 24px;
    color: #333333;
  }
}
.repotPdfHeading {
  display: block;
}
.expDate {
  color: $red-color;
}
.mb-1 {
  margin-bottom: 10px;
}
.pdfUrl {
  display: none;
}
.showIconPdf {
  display: block;
}
@media print {
  .repotPdfHeading {
    display: none;
  }
  .clientPdfDetail {
    display: flex !important;
  }
  .pdfUrl {
    display: block;
    margin-bottom: 10px;
  }
  .showIconPdf {
    display: none;
  }
}
.clientPdfDetail {
  width: 100%;
  display: none;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px !important;
  .clientName {
    font-size: $font-size-24;
    font-weight: 500;
    font-family: $font-lato;
    padding-left: 10px;
  }
  .clientLogo {
    img {
      max-width: 250px;
      max-height: 100px;
      height: 100%;
      width: 100%;
    }
  }
}
.divisionWithsearch {
  .filter_of_caselist {
    .searchcaseid {
      max-width: 100%;
      margin-top: 4px;
    }
  }
}

.MuiAutocomplete-popper {
  .MuiAutocomplete-option {
    text-transform: capitalize;
  }
}

.toolBarBox {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: $fog-grey;
  padding: 20px 30px;
  margin-left: 60px;
}
.inspectionTool {
  .toolBarBox {
    margin-left: 20px;
    padding: 15px;
    .toolBarContainer {
      .toolBarTitle {
        padding-top: 0px;
      }
    }
  }
}
.overRideTextEditor {
  font-size: $font-size-16;
  color: $text-black-color;
  font-weight: 400;
  font-family: $font-lato;
  text-align: left;
  margin-bottom: 15px;
  line-height: 30px;
  b {
    i {
      font-weight: bold;
    }
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
  strong,
  b {
    font-weight: bold;
    color: #333333;
    cursor: default;
    text-decoration: none;
  }

  i {
    font-size: $font-size-16;
    color: $text-black-color;
    font-weight: 400;
    font-family: $font-lato;
    font-style: italic;
  }
  em {
    font-style: italic;
  }
  ol {
    ul {
      padding-left: 0px;
    }
  }
  ul {
    ol {
      padding-left: 0px;
    }
  }

  ul,
  ol {
    padding-left: 15px;
    li {
      margin-bottom: 1px;
      // list-style: inside;
    }
  }

  // ul li {
  //   list-style: inside;
  // }
  h1 {
    font-size: 2em;
    font-weight: bold;
  }
  h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  h4 {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  blockquote {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
  }

  pre {
    display: block;
    font-family: monospace;
    white-space: pre;
    margin: 1em 0px;
  }
}
.mr-2 {
  margin-right: 20px;
}
.ml-2 {
  margin-left: 20px;
}
.mr-1 {
  margin-right: 10px;
}
.ml-1 {
  margin-left: 10px;
}
.addContactProject {
  .overideBtn {
    .containedButton {
      padding: 0 20px;
      margin-top: 5px;
    }
  }
}

.modalContainer {
  .modalBox {
    .modalScroll {
      max-height: 360px;
      overflow: auto;
      overflow-x: auto;
      &::-webkit-scrollbar {
        width: 8px;
        border-radius: 30px;
      }

      &::-webkit-scrollbar-track {
        border: 1px solid $cerulean-color;
        border-radius: 30px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $cerulean-color;
        border-radius: 30px;
      }
    }
  }
}

.editCaseDisbled {
  .overideTimePicker {
    .MuiInputBase-colorPrimary {
      &.Mui-disabled {
        input {
          border-radius: 15px;
          background-color: $fog-grey;
        }
      }
    }
  }
}

.addPermitBtn {
  // margin-bottom: 24px;

  .overideBtn {
    margin-right: 260px;

    .containedButton {
      width: 199px;
      padding: 0;
    }
  }
}
.permitBox {
  display: flex;
}
.rainfalltable {
  .table_style_ui {
    height: 1671px;
    width: 100%;
    .overrideDataGrid {
      .MuiDataGrid-columnHeaders {
        .MuiDataGrid-columnHeadersInner {
          .MuiDataGrid-columnHeader {
            &:last-child {
              .MuiDataGrid-columnHeaderDraggableContainer {
                .MuiDataGrid-columnHeaderTitleContainer {
                  justify-content: flex-end;
                  // padding-right: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.permitTypeFilterBox {
  .overideLables {
    .formLabel {
      text-transform: uppercase;
      font-size: 16px;
      color: #333333;
      font-weight: 600;
    }
  }
}

.closedItemDropboxs {
  position: relative;
  .overideFormDropBox {
    .dropbox {
      border: none;
      height: auto;
      width: auto;
      justify-content: flex-end;
      .multipleIconContainer {
        display: flex;
        margin-top: -70px;
        margin-right: 10px;
      }
    }
  }
}

.newErrorMsg {
  position: absolute;
  color: #f21d1d;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: -6px;
  margin-right: 14px;
}

// @media print {
//   .swinspectiondownloadprint {
//     display: none;
//   }
// }

.drawerContainer {
  display: block;
  @media (max-width: 1180px) {
    display: none;
  }
}

@media print {
  .innerReportAndAnalyticsPdf {
    .removeElement {
      display: none;
    }
  }
}

.imgBox1 {
  position: relative;
  max-width: 100px;
  margin-top: 20px;

  .removeIcon {
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;
    background-color: $cerulean-color;
    color: $white-color;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-14;
    font-weight: 600;
  }
  .borderSignatureImg {
    &.newPermitSignature {
      width: 100%;
      margin-top: 10px;

      max-width: 100px;
    }
  }
}

.checkUserInfoMessage {
  font-size: $font-size-24;
  color: $text-black-color;
  font-weight: 500;
  text-align: center;
  padding: 10px 0px;
}

.tableCheckUser {
  &.viewReportIns {
    td {
      font-size: 14px;
    }
    th {
      font-size: 14px;
    }
  }
}

.catListBox {
  .closeIcon {
    &.closeBtn {
      cursor: auto;
    }
  }
}
.iconWhite {
  color: $white-color;
}

.w-100{
  width: 100%;
}