@import "../../../../scss/variables";

.overrideAdminOpenTicket {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-family: $font-lato;
  overflow: hidden;
  .mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    .topDiv {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .updateIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 150px;
        color: #1e4671;
        font-size: $font-size-14;
        font-weight: 600;
        border: 1px solid #1e4671;
        border-radius: 20px;
        padding: 5px;
        text-align: center;
        cursor: pointer;
      }
      .backBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $font-lato;
        font-weight: 700;
        font-size: 20px;
        color: $text-black-color;
        background: none;
        border: none;
        cursor: pointer;
      }
    }
    .ticketContainer {
      background: $white-color;
      box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.07);
      border-radius: 8px;
      width: 100%;
      padding: 2%;
      margin: 10px 0;
      .idPrioirtyDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .id {
          display: flex;
          align-items: center;
          font-weight: 700;
          font-size: $font-size-14;
          color: $text-black-color;
          font-family: $font-lato;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            height: 30px;
            width: 30px;
            font-family: $font-lato;
          }
          svg {
            height: 16px;
            width: 16px;
          }
          .iconLow {
            color: $white-color;
            background: $malachite;
            margin-right: 6px;
          }
          .iconMedium {
            color: $white-color;
            background: #f99746;
            margin-right: 6px;
          }
          .iconHigh {
            color: $white-color;
            background: #ff5122;
            margin-right: 6px;
          }
        }
        .priorityLow {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 43px;
          height: 22px;
          background: rgba(21, 207, 95, 0.25);
          border-radius: 20px;
          font-family: $font-lato;
          font-weight: 900;
          font-size: $font-size-12;
          color: $malachite;
        }
        .priorityMedium {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 43px;
          height: 22px;
          background: #f9974640;
          border-radius: 20px;
          font-family: $font-lato;
          font-weight: 900;
          font-size: $font-size-12;
          color: #f99746;
        }
        .priorityHigh {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 43px;
          height: 22px;
          background: rgba(255, 81, 34, 0.25);
          border-radius: 20px;
          font-family: $font-lato;
          font-weight: 900;
          font-size: $font-size-12;
          color: #ff5122;
        }
        .priorityClosed {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 43px;
          height: 22px;
          background: $spanish-gray;
          border-radius: 20px;
          font-family: $font-lato;
          font-weight: 900;
          font-size: $font-size-12;
          color: $white-color;
        }
      }
      .ticketDetails {
        text-align: left;
      }
      .question {
        font-weight: 700;
        font-size: $font-size-22;
        color: $text-black-color;
        font-family: $font-lato;
      }
      .answer {
        font-weight: 400;
        font-size: $font-size-16;
        color: $spanish-gray;
        font-family: $font-lato;
        overflow-wrap: break-word;
      }

      .imgDiv {
        height: 200px;
        overflow: hidden;
        border-radius: 20px;
        border: 1px solid $gainsboro;
        padding: 15px;
        max-width: 40%;
        background: #f2f2f2;
        padding: 20px;
        text-align: left;
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: $text-black-color;
        .img {
          object-fit: cover;
          object-position: center center;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .feedBackmsg {
        text-align: left;
        .feedbackIconNeu {
          color: #1e4671;
          margin: 3px;
        }
        .feedbackIconHappy {
          color: #34a853;
          margin: 3px;
        }
        .feedbackIconSad {
          color: #f21d1d;
          margin: 3px;
        }
        h1 {
          font-family: $font-lato;
          font-style: normal;
          font-weight: 700;
          font-size: $font-size-18;
          color: $text-black-color;
        }
        p {
          margin-top: 5px;
          font-family: $font-lato;
          font-style: normal;
          font-weight: 400;
          font-size: $font-size-16;
          color: $text-black-color;
        }
      }
      .chatsContainer {
        display: flex;
        max-height: 300px;
        flex-wrap: wrap;
        overflow-y: scroll;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          width: 0em;
        }

        &::-webkit-scrollbar-track {
          box-shadow: none;
        }

        &::-webkit-scrollbar-thumb {
          background: none;
        }
        .chat {
          width: 100%;
          .adminReplies {
            display: flex;
            align-items: flex-end;
            width: 100%;
            flex-direction: column;
            .reply {
              max-width: 60%;
              background: #f2f2f2;
              border-radius: 60px 0px 60px 60px;
              padding: 20px;
              text-align: left;
              font-family: $font-lato;
              font-style: normal;
              font-weight: 400;
              font-size: $font-size-16;
              color: $text-black-color;
            }
          }
          .userReplies {
            display: flex;
            align-items: flex-start;
            width: 100%;
            flex-direction: column;
            .reply {
              max-width: 60%;
              background: #f2f2f2;
              border-radius: 0px 60px 60px 60px;
              padding: 20px;
              text-align: left;
              font-family: $font-lato;
              font-style: normal;
              font-weight: 400;
              font-size: $font-size-16;
              color: $text-black-color;
            }
            .imgDiv {
              height: 200px;
              overflow: hidden;
              border-radius: 20px;
              border: 1px solid $gainsboro;
              padding: 15px;
              max-width: 60%;
              background: #f2f2f2;
              border-radius: 60px 0 60px 60px;
              padding: 31px 26px 18px 20px;
              text-align: left;
              font-family: "Lato";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              color: $text-black-color;
              .img {
                object-fit: cover;
                object-position: center center;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }
          }
          .createdBy{
            font-weight: 600;
            font-size: 14px;
            margin: 0;
          }
        }
      }
      .replyBtnGrid {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        width: 100%;
        flex-direction: column;
      }
      .resolutionActionDiv {
        display: flex;
        justify-content: space-between;
        .fileUploadDiv {
          display: flex;
          align-items: center;
          font-family: $font-lato;
          .btnColor {
            color: $cerulean-color;
          }
        }
        .resolutionBtnDiv {
          display: flex;
          align-items: center;
          gap: 10px;
          .containedButton {
            width: 150px;
            font-family: $font-lato;
          }
          .outlinedButton {
            background: $white-color;
            border: 1px solid $fossil-grey;
            border-radius: 100px;
            width: 150px;
            color: $text-black-color;
          }
        }
      }
      .chipGroup {
        display: flex;
        gap: 10px;

        .outlinedButton {
          background: $white-color;
          border: 1px solid #e0e0e0;
          border-radius: 100px;
          width: 125px;
          color: $text-black-color;
          text-transform: none;
          font-size: 14px;
          font-family: $font-lato;
          font-weight: 600;
        }
        .sevHigh {
          background: #fed2c6;
          border: 1px solid #fed2c6;
          border-radius: 100px;
          width: 125px;
          color: #fe5123;
          text-transform: none;
          font-size: 14px;
          font-family: $font-lato;
          font-weight: 600;
        }
        .sevMedium {
          background: #fde5d0;
          border: 1px solid #dfdfdf;
          border-radius: 100px;
          width: 125px;
          color: #f89746;
          text-transform: none;
          font-size: 14px;
          font-family: $font-lato;
          font-weight: 600;
        }
        .sevLow {
          background: #c4f3d7;
          border: 1px solid #e0e0e0;
          border-radius: 100px;
          width: 125px;
          color: #15cf5f;
          text-transform: none;
          font-size: 14px;
          font-family: $font-lato;
          font-weight: 600;
        }
      }
      .resolutionReqDiv {
        display: flex;
        align-items: flex-end;
        justify-content: right;
        gap: 10px;
        .outlinedButton {
          background: $white-color;
          border: 1px solid $fossil-grey;
          border-radius: 100px;
          width: 150px;
          color: $text-black-color;
        }
      }
    }
  }
  .css-1uok1cg-MuiGrid-root {
    margin-top: 0;
  }
}

[data-theme="dark"] {
  .overrideOpenTicket {
    font-family: $font-lato;
    .mainContainer {
      .topDiv {
        .backBtn {
          color: $white-color;
        }
      }
      .ticketContainer {
        background: $black-color;
        box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.07);
        .idPrioirtyDiv {
          .id {
            color: $white-color;

            .icon {
              color: $white-color;
              background: $malachite;
            }
          }
          .priority {
            background: rgba(21, 207, 95, 0.25);
            color: $malachite;
          }
        }
        .ticketDetails {
          .question {
            color: $white-color;
          }
          .answer {
            color: $white-color;
          }
        }
        .chat {
          .replies {
            background: $manatee-color;

            .reply {
              color: $white-color;
            }
          }
        }
      }
    }
  }
}
