@import "../../../../scss/variables";

// ticketTopic scss
.overrideFaqTopic {
  // display: flex;
  // justify-content: center;
  // width: 100%;
  // align-items: center;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: $font-lato;
    background: $white-color;
    box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    width: 90%;
    padding: 20px;
    .cardBox {
      border: 2px solid $black-color;
    }
  }
  .topGrid {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 400;
    font-size: $font-size-16;
    align-items: center;
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      border: none;
      font-weight: 400;
      font-size: $font-size-16;
      color: $shadow-grey;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: space-between;
    width: 95%;
  }
  .faqQuestions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 0px 20px 20px;
    gap: 20px;
    background: $white-color;
    border: 1px solid $fog-grey;
    width: 95%;

    h1 {
      font-weight: 700;
      font-size: $font-size-28;
      color: $text-black-color;
    }
    p {
      font-weight: 500;
      font-size: $font-size-18;
      color: $shadow-grey;
      text-align: left;
    }
  }
  .faqQuestions:hover {
    background: #f3f3f3;
    cursor: pointer;
  }
}

.overrideDetailedFaq {
  display: flex;
  width: 100%;
  font-family: $font-lato;
  flex-direction: column;
  align-items: center;

  .detailedFaqContainer {
    display: flex;
    font-family: $font-lato;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    .backBtnGrid {
      display: flex;
      align-items: flex-start;
      width: 100%;
      text-align: left;
      font-family: $font-lato;
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;
        font-weight: 400;
        font-size: $font-size-16;
        color: $shadow-grey;
      }
      .btn:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .borderedGrid {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: $white-color;
      border: 1px solid $fog-grey;
      width: 95%;
      border-radius: 4px;

      .detailsGrid {
        width: 95%;
        .question {
          font-weight: 700;
          font-size: $font-size-28;
          color: $text-black-color;
          text-align: left;
          font-family: $font-lato;
        }
        .answer {
          font-weight: 500;
          font-size: $font-size-18;
          color: $shadow-grey;
          text-align: left;
          font-family: $font-lato;
        }
      }
      .reviewGrid {
        width: 95%;
        display: flex;
        align-items: center;
        font-family: $font-lato;
        .typo {
          font-weight: 600;
          font-size: $font-size-16;
          color: $text-black-color;
          font-family: $font-lato;
        }
        .spanIcon {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #d9d9d9;
          border-radius: 50%;
          text-align: center;
          height: 2.5rem;
          width: 2.5rem;
        }
        .spanIcon:hover {
          cursor: pointer;
        }
        .iconActive {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: $royal-blue-color;
        }
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: $spanish-gray;
        }
      }
      .articlesGrid {
        text-align: left;
        width: 95%;
        font-family: $font-lato;
        .containerHeading {
          font-weight: 700;
          font-size: $font-size-20;
          color: $text-black-color;
          font-family: $font-lato;
        }
        .otheraqArticles {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 20px 0px 20px 20px;
          gap: 20px;
          background: $white-color;
          border: 1px solid $fog-grey;
          width: 100%;
          font-family: $font-lato;

          h1 {
            font-weight: 700;
            font-size: $font-size-28;
            color: $text-black-color;
          }
          p {
            display: flex;
            overflow-y: hidden;
            // max-height: 70px;
            flex-wrap: wrap;
            font-weight: 500;
            font-size: $font-size-18;
            color: $shadow-grey;
            text-align: left;
          }
        }
        .otheraqArticles:hover {
          cursor: pointer;
          background: #f3f3f3;
        }
      }
    }
  }
}

.overrideCreateFaq {
  .createFaqh1 {
    text-align: left;
    font-family: $font-lato;
    font-style: normal;
    font-weight: 700;
    font-size: $font-size-24;
    color: $text-black-color;
  }
}
[data-theme="dark"] {
  .overrideFaqTopic {
    .container {
      background: $black-color;
    }
    .topGrid {
      .btn {
        color: $white-color;
      }
      .btn:hover {
        text-decoration: underline;
      }
    }
    .faqQuestions {
      background: $davy-grey-color;
      border: 1px solid $fog-grey;

      h1 {
        color: $white-color;
      }
      p {
        color: $white-color;
      }
    }
    .faqQuestions:hover {
      background: $space-grey-color;
    }
  }
}

[data-theme="dark"] {
  .overrideDetailedFaq {
    .detailedFaqContainer {
      .backBtnGrid {
        .btn {
          color: $shadow-grey;
        }
        .btn:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .borderedGrid {
        background: $black-color;
        border: 1px solid $fog-grey;

        .detailsGrid {
          width: 95%;
          .question {
            color: $white-color;
          }
          .answer {
            color: $smoke-color;
          }
        }
        .reviewGrid {
          .typo {
            color: $white-color;
          }
          .spanIcon {
            background: #d9d9d9;
          }
          .icon {
            color: $spanish-gray;
          }
        }
        .articlesGrid {
          .containerHeading {
            color: $white-color;
          }
          .otheraqArticles {
            background: $white-color;
            border: 1px solid $fog-grey;

            h1 {
              color: $white-color;
            }
            p {
              color: $shadow-grey;
            }
          }
          .otheraqArticles:hover {
            background: #f3f3f3;
          }
        }
      }
    }
  }
}

.imgDiv {
  height: 200px;
  overflow: hidden;
  border-radius: 20px;
  border: 1px solid $gainsboro;
  padding: 15px;
  max-width: 40%;
  background: #f2f2f2;
  padding: 20px;
  text-align: left;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: $text-black-color;
  .img {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
