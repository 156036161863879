@import "../../../scss/variables";

.overideDatePicker {
  .formControlRoot {
    background: $white-color;
    border-radius: 15px;
    font-family: $font-lato;
    font-style: normal;
    font-weight: 400;
    font-size: $font-size-14;
    color: $manatee-color;
    margin-bottom: 0px;
    svg:hover{
      color: #1B436E;
    }
    .MuiInputBase-root {
      .MuiInputBase-input {
        height: 44px;
        padding: 1.5px 14px;
      }
    }
    .Mui-disabled {
      background-color: $fog-grey;
      border-radius: 15px;
    }
    .MuiOutlinedInput-notchedOutline {
      border-radius: 15px;
      padding: 3px 15px;
      height: 50px;
    }
  }
  .inputRoot {
    border-radius: 16px;
    padding: 10px 15px;
  }
  
  .errorText{
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    color: #f21d1d;
  }
}
[data-testid="CalendarIcon"] {
  color: $text-black-color;
  background-image: url(../../../assets/calendar_month.svg);
  background-repeat: no-repeat;
  background-position: center;
  path {
    display: none;
  }
}

[data-theme="dark"] {
  .overideDatePicker {
    .formControlRoot {
      background-color: $space-grey-color;
      border: 1px solid $fossil-grey;
      color: $white-color;
      .MuiInputBase-root {
        .MuiInputBase-input {
          color: $white-color;
          &:focus {
            border: none;
          }
        }
      }
      &.Mui-disabled {
        background-color: $black-color;
        color: $white-color;
      }
      .inputRoot {
        color: $white-color;
      }
    }
  }
}
