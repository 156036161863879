@import "../../../scss/variables";

.overrideAdminTicketContainer {
  display: flex;
  font-family: $font-lato;
  width: 100%;
  .rightDiv {
    width: 100%;
  }
  .containerTkt {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
  }
  .mainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
  }
  .headerDiv {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-family: $font-lato;
      font-weight: 700;
      font-size: $font-size-22;
      color: $text-black-color;
    }
    .overideBtn .primary_btn_style:hover {
      border: 1px solid #195be1;
    }
  }
  .menuDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .leftMenu {
      width: 50%;
      ul {
        display: flex;
        justify-content: space-between;
        li {
          font-family: $font-lato;
          font-weight: 700;
          font-size: $font-size-16;
          display: flex;
          color: #80b6ec;
        }
        li:hover {
          text-decoration: underline;
        }
      }
    }
    .rightMenu {
      width: 45%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .searchBar {
        width: 70%;
      }

      .filter_btn {
        display: flex;
        cursor: pointer;
      }
      .filterBtn {
        height: 44px;
        width: 100px;
        background: $white-color;
        border: 1px solid $fossil-grey;
        border-radius: 15px;
        padding: 5px 20px;
        .popover {
          width: 250px;
          height: 268px;
          background: $white-color;
          border-radius: 8px;
        }
      }
    }
  }
  .ticketsContainer {
    width: 100%;
  }

  .tabcustom {
    padding: 0px 0px 10px 0px;
    font-size: $font-size-16;
    font-family: $font-lato;
    color: $spanish-gray;
    margin-right: 20px;
    font-weight: 700;
    text-transform: capitalize;
    &.Mui-selected {
      color: $cerulean-color;
    }
    .totalV {
      background-color: $giants-orange;
      padding: 5px;
      height: 22px;
      border-radius: 100%;
      color: $white-color;
      font-family: $font-lato;
      font-size: $font-size-12;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
    }
  }
  .MuiTabs-indicator {
    bottom: 15px;
    background-color: $cerulean-color;
  }

  .tabPanel {
    width: 100%;
    margin-top: 20px;
  }
}

[data-theme="dark"] {
  .overrideAdminTicketContainer {
    .rightDiv {
      .containerTkt {
        .mainContainer {
          .headerDiv {
            h1 {
              color: $white-color;
            }
          }
        }
      }
    }
  }
}
