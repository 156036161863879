@import "../../scss/variables";

.dashboard {
  max-width: 100vw;
  // min-height: 100vh;
  min-height: calc(100vh);
  overflow-x: hidden;
  // padding-bottom: 30px;

  &-header {
    display: flex;
    justify-content: flex-end;
    margin: 10px;
    padding: 5px;

    h1 {
      font-family: "Lato";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #333333;
    }
  }

  &-body {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;

    p {
      font-family: "Lato";
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      letter-spacing: 0.3em;
      color: #333333;
    }
  }
}

.sitelogo {
  margin-right: 750px;
  padding-top: 1rem;
  position: absolute;
  width: 234px;
  height: 93px;
  left: 17px;
  top: 31px;

  img {
    width: 100%;
  }
}

.flex {
  display: flex;
  flex-direction: row;
  text-align: center;
}

.btncaseadddashboard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px 10px 16px;
  gap: 8px;

  position: absolute;
  width: 193px;
  height: 44px;
  left: 950px;
  top: 127px;

  background: #1b436e;
  border-radius: 50px;
  color: #fff;
}
.commingsoon {
  margin-top: 0;
  margin-left: 0px;
  padding-left: 0px;
  display: block;
  .headingcustomige {
    display: flex;
    justify-content: space-between;
    text-align: center;
  }
  .headingcustomige p {
    width: 230px;
    height: 22px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #1b436e;
    cursor: pointer;
  }
}
.heading {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #333333;
  margin-bottom: 20px;
  @media only screen and (max-width: 767px) {
    margin-bottom: 10px;
  }
}
.heading1 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #333333;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
}
.dashboardBody {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 18px;
  max-width: 100%;
  margin-bottom: 40px;
}

.dashboardCardBody {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  width: 23%;
  padding: 24px;
  border-radius: 8px;
  @media (max-width: 767px) {
    width: 50%;
    margin-bottom: 20px;
  }
  @media (max-width: 460px) {
    width: 100%;
    margin-bottom: 20px;
  }
  cursor: pointer;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
}
.icon {
  img {
    width: 22px;
    height: 25px;
  }
}
.text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding: 4px 0px;
  color: #666666;
}
.number {
  font-family: "Lato";
  font-size: 64px;
  color: #1b436e;
}
.graphCardBody {
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  width: 100%;
  min-height: 215px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  .assignedDiv {
    position: absolute;
    top: 60%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    font-family: "Lato";
    h1 {
      font-size: 20px;
      font-weight: 700;
      color: #333333;
    }
    p {
      font-size: 14px;
      font-weight: 600;
      color: #666666;
    }
  }
}
.dashboardBody1 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 100%;
  margin-top: 38px;
}
.hrcard {
  border-top: 1px solid #999999;
  color: #999999;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-top: 25px;
}
.allcards p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #333333;

  margin-top: 20px;
}
.customizedash {
  display: flex;
  cursor: pointer;
}
.customizedash img {
  max-width: 100%;
  max-height: 100%;
  width: 20px;
  height: 20px;
}
.textdash {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding: 18px 0px;
  color: #666666;
  min-height: 76px;
}
.number2 {
  width: 38px;
  padding-top: 5px;
  height: 77px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 77px;
  align-items: center;
  text-align: center;

  /* Primary */

  color: #1b436e;
}
.swdashboardBody {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1px; // inherit
  max-width: 100%;
  margin-bottom: 40px;
  @media (max-width: 1023px) {
    margin-bottom: 20px;
  }
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
  .dashboardCardBody {
    width: 100%;
    padding: 15px;
    min-width: 25%;
    min-height: 192px;
    @media (max-width: 1023px) {
      padding: 10px;
    }
    @media (max-width: 767px) {
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .text {
      @media (max-width: 767px) {
        font-size: $font-size-14;
      }
    }
    .number {
      @media (max-width: 767px) {
        font-size: $font-size-32;
      }
    }
    &.selected {
      border-radius: 8px;
      border: 3px solid #1a446f;
      background: #fff;
    }

    .text {
      text-align: left;
    }
    .number {
      text-align: left;
      &.resourse {
        color: #f11e1e;
      }
      &.draft {
        color: #f69326;
      }
      &.openitem {
        color: #f21d1d;
      }
    }
  }
}

.highlight-post-missedInspection {
  //background-color: red;
  background-color: #fdd;
  border: 1px solid #dfdfdf;
}
.highlight-post-Draft {
  //background-color: yellow;
  background-color: #ffedd2;
  border: 1px solid #dfdfdf;
}
.swdashCustomer {
  display: flex;
  text-align: left;
  gap: 5px;
}

@media screen and (max-width: 1026px) {
  .swdashboardBody {
    display: flex;
    flex-wrap: wrap;
    gap: 1px;
    justify-content: space-between;
    .dashboardCardBody {
      min-width: 25%;
      min-height: 200px;
      @media (max-width: 1023px) {
        min-height: 160px;
        margin-bottom:20px;
      }
      @media (max-width: 767px) {
        min-height: 150px;
        margin-bottom:10px;
      }
    }
  }
}

.seeall p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  // line-height: 22px;
  text-align: right;
  color: #333333;
  cursor: pointer;
  text-align: justify;
}
.settingicn {
  display: flex;
  flex-direction: column;
  padding: 3px;

  .edit-news {
    margin-bottom: 7px;
    display: flex;
    gap: 8px;
    padding: 1px;
  }
  .unpublish-new {
    display: flex;
    padding: 1px;
    gap: 15px;
  }
}
.news p {
  width: 100%;
  line-height: 1.2em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.headingcustomigeCreateNews {
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-wrap: wrap;
}
.headingcustomigeCreateNews p {
  width: 257px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #1b436e;
  cursor: pointer;
  margin-bottom: 10px;
  text-align: left;
}
.rightBar {
  .todayInspection {
    @media (max-width: 1180px) {
      margin-top: 0px;
    }
    @media (max-width: 767px) {
      margin-top: 0px;
    }
  }
}
