@import "../../scss/variables";

.toolBarContainer {
  .toolBarTitle {
    color: $text-black-color;
    font-size: $font-size-20;
    font-weight: 700;
    margin-bottom: 20px;
    font-family: $font-lato;
    padding-top: 20px;
  }
  .toolBarListContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .toolBarList {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      padding: 12px;
      background-color: $white-color;
      margin-bottom: 8px;
      color: $text-black-color;
      font-size: $font-size-14;
      font-weight: 400;
      font-family: $font-lato;
      cursor: pointer;
      &.active {
        background-color: $border-grey;
      }
    }
  }
}
.toolBarBox {
  .otherDetail {
    margin-bottom: 25px;
    h3 {
      color: $cerulean-color;
      margin-bottom: 12px;
      font-size: $font-size-12;
      font-weight: 700;
      font-family: $font-lato;
      line-height: 24px;
    }
    h2 {
      color: $text-black-color;
      margin-bottom: 12px;
      font-size: $font-size-20;
      font-weight: 700;
      font-family: $font-lato;
      line-height: 1.2;
      text-transform: capitalize;
    }
    p {
      color: $text-black-color;
      margin-bottom: 6px;
      font-size: $font-size-14;
      font-weight: 400;
      font-family: $font-lato;
      line-height: 24px;
    }
  }
}
