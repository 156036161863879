@import "../../../scss/variables";

.overrideAdminTicketDashBoard {
  display: flex;
  .rightBarr {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    background: $white-color;
    .loader {
      margin: auto;
    }
    .navbar {
      width: 100%;
      padding-right: 10px;
    }
    .container_wrapperr {
      width: 100%;
      .containerHeading {
        width: 100%;
        text-align: left;
        font-family: $font-lato;
        font-style: normal;
        font-weight: 700;
        font-size: $font-size-24;
        color: $text-black-color;
      }
      .ticketNumbers {
        .totalTickets {
          width: 100%;
          height: 180px;
          background: $white-color;
          box-shadow: 0px 3px 90px rgba(0, 0, 0, 0.1);
          border-radius: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          cursor: pointer;
          .headings {
            font-family: $font-lato;
            font-weight: 500;
            font-size: 16px;
            color: $text-black-color;
            margin: 12px;
          }
          .value {
            font-family: $font-lato;
            font-weight: 700;
            font-size: 64px;
            color: $cerulean-color;
          }
        }
        .pCard {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100%;
          height: 85px;
          box-shadow: 0px 3px 90px rgba(0, 0, 0, 0.1);
          border-radius: 15px;
          cursor: pointer;
          .headings {
            font-family: $font-lato;
            font-style: normal;
            font-weight: 500;
            font-size: $font-size-16;
            color: $white-color;
            margin: 0;
          }
          .value {
            font-family: $font-lato;
            font-weight: 700;
            font-size: 48px;
            color: $white-color;
          }
        }
        .lowCard {
          background: #34a853;
        }
        .mediumCard {
          background: #f0b03e;
        }
        .highCard {
          background: #d30d25;
        }
        .tktTypeCard {
          display: flex;
          width: 100%;
          height: 85px;
          background: $white-color;
          box-shadow: 0px 3px 90px rgba(0, 0, 0, 0.1);
          border-radius: 15px;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          cursor: pointer;
          .headings {
            font-family: $font-lato;
            font-weight: 500;
            font-size: $font-size-14;
            color: $text-black-color;
            margin: 0;
          }
          .value {
            font-family: $font-lato;
            font-weight: 700;
            font-size: 48px;
            color: $cerulean-color;
          }
        }
        
.graphCardBody {
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  width: 100%;
  max-width: 300px;
  min-height: 215px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  .assignedDiv {
    position: absolute;
    top: 60%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    font-family: "Lato";
    h1 {
      font-size: 20px;
      font-weight: 700;
      color: #333333;
    }
    p {
      font-size: 14px;
      font-weight: 600;
      color: #666666;
    }
  }
}
        .analyticscard {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 280px;
          background: $white-color;
          box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
          border-radius: 15px;
          cursor: pointer;
          p {
            text-align: left;
            font-family: $font-lato;
            margin-top: 8px;
            font-size: 16px;
            color: $text-black-color;
            font-weight: 500;
          }
        }
        .assignedDiv {
          position: relative;
          text-align: center;
          top: -120px;
          h1 {
            font-family: $font-lato;
            font-size: 20px;
            font-weight: 700;
          }
          p {
            font-family: $font-lato;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
      .graphDiv {
        // height: 546px;
        background: $white-color;
        border: 1px solid $deepveer;
        border-radius: 8px;
        .line {
          border-bottom: 1px solid #dfe0eb;
        }
        .graphCard {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          height: 105px;
          font-family: $font-lato;
          h2 {
            font-weight: 700;
            font-size: $font-size-24;
            line-height: 30px;
            color: #252733;
          }
          h1 {
            font-weight: 600;
            font-size: $font-size-16;
            line-height: 22px;
            text-align: center;
            color: #9fa2b4;
          }
        }
      }
      .customerSatisfaction {
        height: 209px;
        background: $white-color;
        box-shadow: 0px 3px 90px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        padding: 50px;
        cursor: pointer;
        .header {
          text-align: left;
          font-family: $font-lato;
          .h1 {
            font-weight: 500;
            font-size: $font-size-16;
            color: $text-black-color;
          }
          .h2 {
            font-weight: 500;
            font-size: $font-size-12;
            color: $text-black-color;
          }
        }
        .satisFactionCards {
          .totalCard {
            flex-direction: column;
          }
          .tktTypeCard {
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 85px;
            background: $approx-snow;
            border: 1px solid rgba(102, 102, 102, 0.5);
            box-shadow: 0px 3px 90px rgba(0, 0, 0, 0.1);
            border-radius: 15px;
            font-family: $font-lato;
            cursor: pointer;
            .headings {
              font-weight: 500;
              font-size: $font-size-16;
              color: $text-black-color;
            }
            .value {
              font-weight: 700;
              font-size: $font-size-36;
            }
            .blue {
              color: #1d4570;
            }
            .green {
              color: $green-color;
            }
            .red {
              color: $red-color;
            }
            .icon {
              height: 33px;
              width: 33px;
            }
            .neutrol {
              color: #1e4671;
            }
            .satisfied {
              color: $green-color;
            }
            .disSatisfied {
              color: $red-color;
            }
          }
        }
      }
    }
  }
}
