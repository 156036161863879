@import "../../../../scss/variables";
.overrideadmincategory {
  .controlNameBox {
    .controlTitile {
      font-size: $font-size-24;
      font-weight: 700;
      color: $text-black-color;
      font-family: $font-lato;
    }
    .controlSubTitle {
      font-size: $font-size-18;
      font-weight: 700;
      color: $text-black-color;
      font-family: $font-lato;
    }
  }
  .errorMsg {
    color: #f21d1d;
    font-size: $font-size-12;
    font-family: $font-lato;
  }
  .heading {
    font-family: $font-lato;
    font-size: $font-size-16;
    font-weight: 700;
    color: #000000;
  }
}
.catListBox {
  // border: 1px solid $gainsboro;
  // padding: 15px 25px;
  font-size: $font-size-16;
  font-weight: 400;
  font-family: $font-lato;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .isActiveBox {
    flex-direction: row;
    align-items: center;
  }
  .closeIcon {
    cursor: pointer;
  }
}

.richTextControl {
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background: none;
  }
  ul li strong em h1 h2 h3 h4 h5 blockquote pre{
    // word-wrap: break-word;
    white-space: balance;
    // text-overflow: ellipsis;
  }
  p{
    white-space: balance;
   
  }
  ul {
    text-align: left;
    li {
      margin-bottom: 25px;
      font-size: $font-size-18;
      color: $text-black-color;
      font-weight: 500;
      padding-left: 20px;
      position: relative; 
      white-space: balance;
   
      &::after {
        position: absolute;
        content: "";
        top: 12px;
        left: 0;
        background-color: $text-black-color;
        width: 5px;
        height: 5px;
        border-radius: 10px; 
        white-space: balance;
   
      }
    }
  }
  color: $text-black-color;
  font-weight: 400;
  font-family: $font-lato;
  text-align: left;
  margin-bottom: 15px;
  line-height: 30px;
  white-space: balance;
   
  strong {
    font-weight: bold;
    color: #333333;
    white-space: balance;
   
  }
  em {
    font-style: italic;
    white-space: balance;
   
  }
  ul {
    li {
      margin-bottom: 1px;
      white-space: balance;
   
      // list-style: inside;
    }
  }
  h1 {
    font-size: 2em;
    font-weight: bold;
    white-space: balance;
   
  }
  h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    white-space: balance;
   
  }
  h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    white-space: balance;
   
  }
  h4 {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    white-space: balance;
   
  }
  blockquote {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
    white-space: balance;
   
  }
  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    white-space: balance;
   
  }
  pre {
    display: block;
    font-family: monospace;
    white-space: pre;
    margin: 1em 0px;
    white-space: balance;
   
  }
}
.filter_of_caselist .filter_btn button {
  width: 100%;
  padding: 5px 20px;
  line-height: 28px;
  font-size: 16px;
  font-family: "Lato";
  border: 1px solid #dde2e5;
  border-radius: 15px;
  outline: none;
  color: #666666;
  background-color: transparent;
  margin-left: 10px;
  font-weight: 600;
  cursor: pointer;
}
