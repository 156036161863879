@import "../../../scss/variables";

body {
  &.dark-mode {
    .overideBtn {
      .containedButton {
        background-color: $dark-primary-color;
        color: $dark-primary-text-color;
        &:hover {
          background-color: $dark-secondary-background-color;
          color: $dark-primary-text-color;
        }
      }
      .textButton {
        color: $dark-primary-text-color;
        &:hover {
          background-color: $dark-secondary-background-color;
        }
      }
      .outlinedButton {
        color: $dark-primary-text-color;
        &:hover {
          background-color: $dark-secondary-background-color;
        }
        span {
          svg {
            color: $dark-primary-color;
          }
        }
      }
      .primary_btn_style {
        color: $dark-primary-text-color;
        background-color: $dark-primary-color;
        &:hover {
          background-color: $dark-secondary-background-color;
          color: $dark-primary-text-color;
        }
      }
      .outlinedButton {
        border: 1px solid $dark-primary-color;
        color: $dark-primary-color;
      }
      .primaryassign_btn_style {
        color: $dark-primary-text-color;
        background-color: $dark-primary-color;
        &:hover {
          background-color: $dark-secondary-background-color;
          color: $dark-primary-text-color;
        }
      }
    }
  }
  .overideBtn {
    .containedButton {
      padding: 0px 38px;
      height: 44px;
      background-color: $cerulean-color;
      border-radius: 100px;
      text-transform: capitalize;
      max-width: 300px;
      font-family: $font-lato;
      font-size: $font-size-14;
      font-weight: 600;
      line-height: $font-size-22;
      box-shadow: none;
    }
    .containedButtonExport {
      //padding: 0px 38px;
      height: 36px;
      background-color: $green-color;
      inline-size: max-content;
      border-radius: 20.5px;
      text-transform: capitalize;
      max-width: 300px;
      font-family: $font-lato;
      font-size: $font-size-14;
      font-weight: 400;
      line-height: $font-size-14;
      width: 136px;
    }
    .addIconBtns {
      color: $white-color;
    }
    .addIconalertBtns {
      color: $white-color;
    }
    .addIconBtnsblue {
      color: $cerulean-color;
    }
    .addIconGrey {
      color: $sky-grey-color;
    }
    .textButton {
      font-family: $font-lato;
      font-style: normal;
      font-weight: 700;
      font-size: $font-size-14;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: $cerulean-color;
      text-transform: capitalize;
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .outlinedButton {
      border: 1px solid $cerulean-color;
      border-radius: 100px;
      color: $cerulean-color;
      width: 200px;
      text-transform: capitalize;
      font-family: $font-lato;
      box-shadow: none;
    }
    .varientTextGreens {
      color: $green-color;
      font-family: $font-lato;
    }
    .addIconBtn {
      color: $green-color;
      font-family: $font-lato;
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-14;
      line-height: 20px;
    }
    .primary_btn_style {
      border: 1px solid $cerulean-color;
      border-radius: 50px;
      padding: 8px 25px;
      background-color: $cerulean-color;
      color: $white-color;
      text-transform: none;
      font-weight: 600;
      font-family: $font-lato;
      .Buttons:hover {
        border: none;
      }
      svg {
        color: $white-color;
      }
      .Button:focus {
        outline: none;
      }
      .Buttons:focus {
        outline: none;
      }
    }
    .redBtn {
      padding: 0px 38px;
      height: 40px;
      background-color: $nobg;
      border-radius: 100px;
      text-transform: capitalize;
      max-width: 230px;
      &:hover {
        background-color: $stolen-bg;
      }
    }
    .primary_btn_style.red_bg {
      background-color: $red-color;
      border-color: $red-color;
    }
    .primary_btn_style:hover {
      background-color: $saphire-blue-color;
    }
    .primary_btn_style.red_bg:hover {
      background-color: $red-color;
    }

    .primaryalert_btn_style {
      border-radius: 4px;
      padding: 10px 10px;
      background: #ff5122;
      color: $white-color;
      text-transform: none;
      font-weight: 400;
      width: 80px;
      font-family: $font-lato;
      height: 36px;
      &:hover {
        background-color: #ff5122;
      }
    }
    .primaryalertAll_btn_style {
      border-radius: 4px;
      padding: 10px 10px;
      background: #ff5122;
      color: $white-color;
      text-transform: none;
      font-weight: 400;
      width: 98px;
      font-family: $font-lato;
      height: 36px;
      &:hover {
        background-color: #ff5122;
      }
    }

    .primaryassign_btn_style {
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      border-radius: 8px;
      padding: 19px 20px;
      background: $cerulean-color;
      color: $white-color;
      text-transform: none;
      width: 102px;
      height: 36.48px;
      &:hover {
        background-color: $cerulean-color;
      }
    }
    .containedButtonGrey {
      padding: 0px 38px;
      height: 44px;
      background-color: #d9d9d9;
      border-radius: 100px;
      text-transform: capitalize;
      max-width: 300px;
      font-family: $font-lato;
      font-size: $font-size-14;
      font-weight: 600;
      line-height: $font-size-22;
      box-shadow: none;
      color: #333333;
    }
  }

  .smallAddBtn {
    .overideBtn {
      .containedButton {
        width: 100px;
      }
    }
  }

  .meduimAddBtn {
    .overideBtn {
      .containedButton {
        width: 180px;
      }
    }
  }

  .variantOutlineBorderSmall {
    .overideBtn {
      .outlinedButton {
        max-width: 150px;
        height: 44px;
      }
    }
  }

  .overideBtn .primary_btn_style:hover {
    border: 1px solid $saphire-blue-color;
  }
}
.lmsmappingbtn {
  .overideBtn {
    .containedButton {
      padding: 0px;
    }
  }
}

.variantOutlineBorderSmall {
  .overideBtn {
    .outlinedButton {
      max-width: 150px;
      height: 44px;
    }
  }
}

.overideBtn .primary_btn_style:hover {
  border: 1px solid $saphire-blue-color;
}
.lmsmappingbtn {
  .overideBtn {
    .containedButton {
      padding: 0px;
    }
  }
}
