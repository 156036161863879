@import "../../../../scss/variables";
.viewOverideContact {
  .commonOveride {
    margin-top: 0px;
  }
}
.filterbtn:hover {
  cursor: pointer;
}
.filterbtn {
  margin-left: 10px;
}
.contactswitch .MuiFormControlLabel-label {
  margin-left: 15px;
  font-family: $font-lato;
}

.contactswitch .MuiFormLabel-colorPrimary {
  font-family: $font-lato;
}
.addcontactbtn {
  display: flex;
  justify-content: flex-end;
}
