@import "../../../scss/variables";

.overrideDeletableChip {
  .chip {
    height: 44px;
    background: $white-color;
    color: $cerulean-color;
    border: 1px solid $cerulean-color;
    border-radius: 15px;
    span {
      font-family: $font-lato;
      color: $cerulean-color;
      font-size: $font-size-14;
      font-weight: 600;
      text-transform: capitalize;
    }
    svg {
      color: $cerulean-color;
      font-size: $font-size-18;
    }
  }
}
