@import "../../../../scss/variables";
.ViewIncHeaderOver {
  margin-bottom: 27px;
  .viewIncBox {
    background-color: $white-color;
    margin-top: 25px;
    padding: 25px;
    .viewIncBoxWrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .viewIncBoxLeft {
        text-align: left;
        .caseId {
          font-weight: 700;
          font-size: $font-size-16;
          line-height: 19px;
          color: $cerulean-color;
          font-family: $font-lato;
          span {
            text-transform: uppercase;
            color: $cerulean-color;
            font-size: $font-size-16;
            font-weight: 700;
            padding-left: 5px;
            font-family: $font-lato;
          }
        }
        .reportid {
          font-weight: 700;
          font-size: $font-size-24;
          line-height: 29px;
          color: $text-black-color;
          font-family: $font-lato;
          span {
            text-transform: uppercase;
            font-weight: 700;
            font-size: $font-size-24;
            line-height: 29px;
            color: $text-black-color;
            font-family: $font-lato;
            padding-left: 5px;
          }
        }
        .reportedby {
          font-weight: 400;
          font-size: $font-size-16;
          line-height: 19px;
          color: $text-black-color;
          font-family: $font-lato;
          margin: 4px 0px;
          span {
            text-transform: capitalize;
            font-weight: 700;
            font-size: $font-size-16;
            line-height: 19px;
            color: $text-black-color;
            font-family: $font-lato;
            padding-left: 5px;
          }
        }
        .conductedon {
          font-weight: 400;
          font-size: $font-size-16;
          line-height: 19px;
          color: $text-black-color;
          font-family: $font-lato;
          margin: 4px 0px;

          span {
            text-transform: uppercase;
            font-weight: 400;
            font-size: $font-size-16;
            line-height: 19px;
            color: $text-black-color;
            font-family: $font-lato;
            padding-left: 5px;
          }
        }
        .project {
          font-weight: 400;
          font-size: $font-size-16;
          line-height: 19px;
          color: $text-black-color;
          font-family: $font-lato;
          margin: 4px 0px;

          span {
            text-transform: uppercase;
            font-weight: 700;
            font-size: $font-size-16;
            line-height: 19px;
            color: $text-black-color;
            font-family: $font-lato;
            padding-left: 5px;
          }
        }
      }
      .viewIncBoxRight {
        .incstatus {
          font-weight: 700;
          font-size: $font-size-16;
          line-height: 19px;
          color: $text-black-color;
          font-family: $font-lato;
          margin-bottom: 8px;
        }
        .statusbtn {
          background-color: $malachite;
          border: 1px solid $malachite;
          border-radius: 23px;
          max-width: 200px;
          min-width: 200px;
          width: 100%;
          text-transform: none;
          font-weight: 700;
          font-size: $font-size-16;
          color: $white-color;
          font-family: $font-lato;
          box-shadow: none;
          &.cursonDefault {
            cursor: default;
          }
        }
      }
    }
  }
}
