@import "../../../scss/variables";

.customTableWrapper {
  .tableContainer {
    border: 1px solid $gainsboro;
    overflow: auto;
    width: 100%;
    max-height: 100vh;
    &.autoHeight {
      height: auto;
    }
    table {
      width: 100%;
      thead {
        width: 100%;
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 1;
        .column {
          background-color: $Bright-Gray;
          .kudosBg {
            background-color: rgba(52, 168, 83, 0.15);
          }
          .currectiveBg {
            background: rgba(255, 0, 0, 0.15);
          }
          th {
            flex: 1;
            text-align: left;
            padding: 10px 20px;
            font-size: $font-size-16;
            color: $text-black-color;
            font-weight: 600;
            font-family: $font-lato;
            cursor: pointer;
            span {
              font-size: $font-size-16;
              color: $text-black-color;
              font-weight: 600;
              font-family: $font-lato;
              display: flex;
              align-items: center;
              svg {
                width: 20px;
                margin-left: 5px;
              }
            }
          }
        }
      }
      .row {
        width: 100%;
        border-bottom: 1px solid $gainsboro;
        &.customRowColor {
          .kudosBg {
            background-color: rgba(52, 168, 83, 0.15);
          }
          .currectiveBg {
            background: rgba(255, 0, 0, 0.15);
          }
        }
        td {
          flex: 1;
          text-align: left;
          padding: 10px 20px;
          font-size: $font-size-16;
          color: $text-black-color;
          font-weight: 500;
          font-family: $font-lato;
          .link {
            color: $light-primary-color;
            font-size: $font-size-16;
            font-weight: 500;
            font-family: $font-lato;
            border-bottom: 1px solid $light-primary-color;
            padding-bottom: 3px;
            cursor: pointer;
          }
          .persantage {
            color: $green-color;
            &.minus {
              color: $red-color;
            }
          }
        }
      }
    }
  }
}
