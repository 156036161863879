@import "../../../scss/variables";
.observationOveride {
  .titleViolation {
    font-family: $font-lato;
    font-weight: 700;
    font-size: $font-size-20;
    line-height: 24px;
    color: $nobg;
  }
  .titleAction {
    font-family: $font-lato;
    font-weight: 700;
    font-size: $font-size-20;
    color: #1b436e;
  }
  .titleKudos {
    font-family: $font-lato;
    font-weight: 700;
    font-size: $font-size-20;
    line-height: 24px;
    color: $malachite;
  }
  .commonOveride {
    margin-top: 40px;
    margin-left: 20px;
    .commoninfoWrap {
      background: $white-color;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 15px;
      padding: 12px 25px 16px 25px;
      .commoninfoBox {
        display: flex;
        justify-content: space-between;
        @media (max-width: 767px) {
          flex-direction: column;
          flex-wrap: wrap;
        }
        .commoninfoleft {
          width: 70%;
          text-align: left;
          @media (max-width: 767px) {
            width: 100%;
          }
          .titleViolation {
            font-family: $font-lato;
            font-weight: 700;
            font-size: $font-size-20;
            line-height: 24px;
            color: $nobg;
          }
          .titleKudos {
            font-family: $font-lato;
            font-weight: 700;
            font-size: $font-size-20;
            line-height: 24px;
            color: $valid-color;
          }
          .CaseID {
            font-weight: 700;
            font-family: $font-lato;
            font-size: 16px;
            color: #1b436e;
            .caseNo {
              font-weight: 700;
              font-family: $font-lato;
              font-size: 16px;
              color: #1b436e;
            }
          }

          .reportid {
            font-weight: 700;
            font-size: $font-size-24;
            line-height: 29px;
            color: $cerulean-color;
            font-family: $font-lato;
            span {
              text-transform: uppercase;
              font-weight: 700;
              font-size: $font-size-24;
              line-height: 29px;
              color: $text-black-color;
              font-family: $font-lato;
              padding-left: 5px;
            }
          }
          .redirectPage {
            cursor: pointer;
            position: absolute;
          }
          .CaseId {
            font-weight: 700;
            font-size: $font-size-16;
            line-height: 19px;
            color: $cerulean-color;
            font-family: $font-lato;
            span {
              text-transform: uppercase;
              color: $cerulean-color;
              font-size: $font-size-16;
              font-weight: 700;
              padding-left: 5px;
              font-family: $font-lato;
            }
          }
        }

        .commoninfoRight {
          width: 25%;
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          align-items: center;
          @media (max-width: 767px) {
            width: 100%;
            border: 2px solid black;
          }
          .previousCorrective {
            font-weight: 700;
            font-size: $font-size-16;
            color: $text-black-color;
            font-family: $font-lato;
            margin-bottom: 8px;
          }
          .incstatus {
            font-weight: 700;
            font-size: $font-size-16;
            color: $text-black-color;
            font-family: $font-lato;
            margin-bottom: 8px;
            text-align: center;
            .violationBox {
              .MuiGrid-root {
                margin-left: 30px;
              }
            }
          }
          .statusbtn {
            background-color: $malachite;
            border: 1px solid $malachite;
            border-radius: 23px;
            max-width: 176px;
            min-width: 176px;
            width: 126px;
            height: 41px;
            font-weight: 700;
            font-size: $font-size-16;
            color: $white-color;
            font-family: $font-lato;
            box-shadow: none;
            &.cursonDefault {
              cursor: default;
            }
          }
          .statusbtnOpen {
            background-color: $nobg;
            border: 1px solid $nobg;
            border-radius: 23px;
            max-width: 176px;
            min-width: 176px;
            width: 126px;
            height: 41px;
            font-weight: 700;
            font-size: $font-size-16;
            color: $white-color;
            font-family: $font-lato;
            box-shadow: none;
            &.cursonDefault {
              cursor: default;
            }
          }

          .reprimandstatusbtn {
            background: $nobg;
            width: 100px;
            height: 100px;
            font-family: $font-lato;
            font-weight: 700;
            font-size: $font-size-64;
            margin-left: 45px;
            color: $white-color;
            &.cursonDefault {
              cursor: grab;
            }
          }
          .reprimandstatusbtn:hover {
            cursor: default;
          }
        }
      }
    }
    .correctivemeasures {
      font-family: $font-lato;
      font-weight: 600;
      font-size: $font-size-16;
      color: $shadow-grey;
      text-align: left;
      .correctivemeasuredes {
        display: block;
        width: 100%;
        margin-top: 12px;
        background: $white-color;
        border: 1px solid $fossil-grey;
        border-radius: 15px;
        width: 634px;
        height: 94px;
        display: flex;
        flex-direction: left;
      }
    }
    .reprimandcheckbox {
      font-family: $font-lato;
      font-weight: 400;
      font-size: $font-size-16;
      color: $text-black-color;
    }
    .style-checkbox {
      position: absolute;
      opacity: 0;
      z-index: 9;

      & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        font-size: $font-size-14;
        font-weight: 400;
        font-family: $font-lato;
      }

      & + label:before {
        content: "";
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 12px;
        height: 12px;
        border: 2px solid $cerulean-color;
        border-radius: 2px;
        margin-top: -1px;
      }

      &:checked + label:before {
        background: $cerulean-color;
      }

      &:disabled + label {
        color: $philippine-silver;
        cursor: auto;
      }

      &:disabled + label:before {
        box-shadow: none;
        background: $gainsboro;
      }

      &:checked + label:after {
        content: "";
        position: absolute;
        left: 3px;
        top: 6px;
        background: $white-color;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 $white-color, 4px 0 0 $white-color,
          4px -2px 0 $white-color, 4px -4px 0 $white-color,
          4px -6px 0 $white-color, 4px -8px 0 $white-color;
        transform: rotate(45deg);
      }
    }
    .escalatereprimand {
      width: 70%;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      @media (max-width: 767px) {
        width: 100%;
      }
      .closereprimandbtn {
        width: 126px;
        height: 41px;
        background: $cerulean-color;
        // border: 1px solid $chinese-white;
        border-radius: 23px;
        font-family: $font-lato;
        font-weight: 600;
        font-size: $font-size-16;
        color: $white-color;
        text-transform: capitalize;

        .closereprimandbtn:focus {
          outline: none;
          border: none;
        }
      }
    }
  }
}
